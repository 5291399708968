.filepond--credits {
  display: none;
}

.filepond--drop-label {
  font-size: var(--regularFontSize) !important;
  //min-height: 100px !important;
  
  label {
    width: 100%;
    height: 100%;
    padding: 15px !important;
    
    .FileUploadTextContainer {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      
      span {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

.filepond--label-action {
  text-decoration: none;
}

.filepond--wrapper {
  //height: 100%;
  width: 100%;
  //margin-bottom: 25px;

  .filepond--root {
    height: 100%;
    margin: 0;
  }
}

.filepond--drip {
  background: none !important;
  border-radius: var(--roundCorner1);
  border: 5px dashed rgba(0, 0, 0, 0.5);
}