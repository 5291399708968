﻿/* --------------- Table of contents --------------- */

/* 1: Variables */
/*  1.1: Typography */
/*  1.2: Colors */
/*  1.3: Shadow */
/*  1.4: Spacing */
/*  1.5: borders */
/*  1.6: Icons */
/*  1.7: Animations */

/* 2: Typography */

/* 3: General styling */
/*  3.1: Buttons */
/*  3.2: Button Containers */
/*  3.3: Scrollbar */
/*  3.4: Loading Steps */
/*  3.5: Daterange Picker */
/*  3.6: FullCalender */
/*  3.7: Tooltips */
/*  3.8: Feedback */
/*  3.9: Spinner */
/*  3.10: SWAL2 */
/*  3.11: Bootstrap Tags */
/*  3.12: Background */
/*  3.13: Autocomplete */

/* 4: Header */

/* 5: Menu */
/*  5.1: Settings Menu */

/* 6: Body */
/*  6.1: Correctional */
/*  6.2: Modules */
/*      6.2.1: Groups */
/*      6.2.2: Modules General */
/*      6.2.3: Modules Netwerk */
/*      6.2.4: Modules Controllers */
/*      6.2.5: Modules Shop */
/*      6.2.6: Advisory Aansluitpunten */
/*      6.2.7: Advisory Steering */
/*  6.3: Page Header */
/*  6.4: Page Body */
/*  6.5: Error Page */
/*  6.6: Data Tables */
/*  6.7: Legend */
/*  6.8: iframes */
/*  6.9: Comparison */
/*  6.10: Productlist */
/*  6.11: Marketplace */
/*  6.12: Accordeon */

/* 7: Modals */
/*  7.1: Modals General */
/*  7.2: Version */
/*  7.3: Shortcuts */

/* 8: Charts */
/*  8.1: Main Chart */
/*  8.2: Netwerk Chart */
/*  8.3: Chart Spinner */
/*  8.4: Chart Error */

/* 9: Map */

/* 10: Responsive */

/* 11: Activators/Deactivators */

/* 12: Partial Supported Code */

/* 12: Fieldset */



/* --------------- 2: Typography --------------- */





.content-groupName > h3 {
    font-family: $default-font-family-headline;
    color: $default-font-color-dark;
    font-size: var(--bigFontSize);
    font-weight: var(--fontWeight4);
    position: relative;
    margin: 25px 0;
    width: calc(100%);
    padding: 25px 50px;
    border-bottom: 2px solid var(--buttonColorRed);
}

.content-group-header > h3 {
    font-family: $default-font-family-headline;
    font-size: var(--titleFontSize);
    font-weight: var(--fontWeight4);
    margin-bottom: 0;
    margin-right: auto;
    margin-left: 50px;
}

h5 {
    font-weight: var(--fontWeight3);
    font-family: $default-font-family-headline;
    font-size: var(--titleFontSize);

    @include size(tablet) {
        font-size: 2em;
    }
}

.page-header h5 {
    font-size: var(--bigFontSize);
    font-weight: var(--fontWeight4);
    margin-bottom: 5px;
}

.module-header h5 {
    font-size: 1.5em;
    font-weight: var(--fontWeight4);
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    max-width: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

h5 i {
    margin: 0 0 0 5px;
    font-size: calc(var(--titleFontSize) - 5px);
}

h6 {
    color: var(--textColorLight);
}

h4 {
    color: $default-font-color-dark;
    font-size: var(--inputFontSize);
}

.breadcrumb-item > a {
    color: var(--textColorGrey);
    font-style: italic;
    font-size: $default-font-size;
    display: flex;
    align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: flex;
    align-items: center;
}

.breadcrumb-item.breadcrumb-btn + .breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #6c757d;
    content: "";
}

.breadcrumb-btn > a {
    background: var(--textColorGrey);
    color: var(--textColorLight);
    border-radius: 10px;
    padding: 2px 10px;
}

    .breadcrumb-btn > a > i {
        margin: 0 5px 0 0;
    }

.error-headline {
    font-family: $default-font-family-headline;
    /*font-size: clamp(40px, 10vw, 150px);*/
    font-size: clamp(40px, 3vw, 150px);
    font-weight: var(--fontWeight4);
    text-align: center;
    margin-bottom: var(--marginBottom1);
}

.error-subheading {
    text-align: center;
    font-size: clamp(17px, 4vw, 40px);
    font-weight: var(--fontWeight2);
    margin-bottom: var(--marginBottom1);
}

.shortcut {
    margin: 0 5px;
}

.text-link {
    color: var(--styleColor1);
    cursor: pointer;
}

    .text-link:hover {
        color: var(--styleColor2);
    }

.login-error {
    color: var(--textColorRed);
}

.info-text-underTitle {
    width: 100%;
    text-align: center;
}

.smaller-title-text {
    font-size: $default-font-size;
    font-family: $default-font-family-text;
    font-weight: var(--fontWeight2);
}

.product-price-suffix {
    font-size: $default-font-size;
}

kbd {
    width: fit-content;
    height: fit-content;
}


/* --------------- 3: General styling --------------- */
/* ---------- 3.1: Buttons ---------- */


img {
    object-fit: contain;
}



.content-btn-switch {
    position: relative;
    display: inline-block;
    width: 30px !important;
    height: 16px;
}

    .content-btn-switch > input[type="checkbox"] {
        opacity: 0;
        width: 0;
        height: 0;
    }

        .content-btn-switch > input[type="checkbox"]:checked + .content-btn-slider {
            background-color: var(--styleColor3);
        }

            /*.content-btn-switch > input[type="checkbox"]:focus + .content-btn-slider {
            box-shadow: 0 0 2px var(--styleColor1);
        }*/

            .content-btn-switch > input[type="checkbox"]:checked + .content-btn-slider:before {
                -webkit-transform: translateX(14px);
                -ms-transform: translateX(14px);
                transform: translateX(14px);
            }

.content-btn-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    background-color: var(--backgroundColor3);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

    .content-btn-slider:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 3px;
        bottom: 3px;
        border-radius: 50px;
        background-color: var(--styleColor2);
        -webkit-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
    }

.content-btn-switch-before {
    margin-right: 15px;
}

.content-btn-switch-after {
    margin-left: 15px;
}

.content-btn-switch-container {
    display: inline-flex;
}

.content-btn a {
    color: var(--textColorLight);
}

.content-btn-disabled {
    cursor: not-allowed !important;
}

.content-btn.disabled {
    background: var(--buttonColorGrey);
    cursor: not-allowed;
    /* -webkit-box-shadow: 0 5px 7px 0 rgba(var(--buttonColorGreyRGB),0.3);
    box-shadow: 0 5px 7px 0 rgba(var(--buttonColorGreyRGB),0.3);*/
    -webkit-box-shadow: 0 5px 7px 0 rgba(170, 170, 170,0.3);
    box-shadow: 0 5px 7px 0 rgba(170, 170, 170,0.3);
}

    .content-btn.disabled:hover {
        -webkit-box-shadow: rgba(170, 170, 170,0.16) 0 10px 20px, rgba(170, 170, 170,0.3) 0 6px 6px;
        box-shadow: rgba(170, 170, 170,0.16) 0 10px 20px, rgba(170, 170, 170,0.3) 0 6px 6px; /*
        -webkit-box-shadow: rgba(var(--buttonColorGreyRGB),0.16) 0 10px 20px, rgba(var(--buttonColorGreyRGB),0.3) 0 6px 6px;
        box-shadow: rgba(var(--buttonColorGreyRGB),0.16) 0 10px 20px, rgba(var(--buttonColorGreyRGB),0.3) 0 6px 6px;*/
    }

    .content-btn.disabled:active {
        -webkit-box-shadow: rgba(170, 170, 170,0.12) 0 1px 3px, rgba(170, 170, 170,0.24) 0 1px 2px;
        box-shadow: rgba(170, 170, 170,0.12) 0 1px 3px, rgba(170, 170, 170,0.24) 0 1px 2px; /*
        -webkit-box-shadow: rgba(var(--buttonColorGreyRGB),0.12) 0 1px 3px, rgba(var(--buttonColorGreyRGB),0.24) 0 1px 2px;
        box-shadow: rgba(var(--buttonColorGreyRGB),0.12) 0 1px 3px, rgba(var(--buttonColorGreyRGB),0.24) 0 1px 2px;*/
    }

.content-btn .spinner-border {
    font-size: 10px;
    height: 13px;
    width: 13px;
    margin-left: 10px;
    position: relative;
    top: -2px;
}

.module-options {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    width: unset;
    height: unset;
    position: relative;
    top: 1px;
    cursor: pointer;
}

button:focus {
    outline: none;
}

/*.content-btn:focus-visible, .form-control:focus-visible {
    outline: none !important;
    box-shadow: 0 0 0 2px var(--focusColor1) !important;
    border: none !important;
}*/

*:focus-visible {
    outline: none !important;
    box-shadow: 0 0 0 2px var(--focusColor1) !important;
    border: none !important;
}

.content-btn .fa, .content-btn .fas, .content-btn .fim, .content-btn .far, .content-btn .fab {
    margin-right: 10px;
}

/*.content-btn .fa:only-child, .content-btn .fas:only-child, .content-btn .fim:only-child, .content-btn .far:only-child, .content-btn .fab:only-child {
        margin-right: 0;
    }*/

.content-btn-onlyIcon {
    margin-right: 0 !important;
}

.fas.fa-filter {
    font-size: calc(var(--inputFontSize) - 3px);
}

.form-check {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 0;
    
    &, input, label {
        cursor: pointer;
    }
}

.form-check-input {
    margin: 0 5px 0 0;
    position: relative;
}

.content-btn-chartGroup {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
}

    .content-btn-chartGroup:hover {
        color: var(--buttonColorGreen);
    }

.content-btn-basket-container {
    margin-right: auto;
    position: relative;
}

    .content-btn-basket-container > .basket-case {
        position: absolute;
        background: var(--backgroundColor1);
        border-radius: $default-border-radius;
        padding: var(--padding2);
        -webkit-box-shadow: var(--shadow2);
        box-shadow: var(--shadow2);
        bottom: 100%;
        min-width: 250px;
        width: -webkit-fit-content;
        width: fit-content;
        width: -moz-fit-content;
    }


        .content-btn-basket-container > .basket-case > ul {
            padding: 0;
            margin: 0;
            list-style: none;
        }


            .content-btn-basket-container > .basket-case > ul > li > p {
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
                width: 100%;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
            }


        .content-btn-basket-container > .basket-case > p.basket-case-total {
            border-top: var(--borderBottom1);
            margin: 0;
            padding: 10px 0 0 0;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            width: 100%;
        }


            .content-btn-basket-container > .basket-case > p.basket-case-total > span {
                font-weight: var(--fontWeight3);
                margin: 0 10px 0 0;
            }

.content-btn-basket {
    position: relative;
}

    .content-btn-basket > i.fas {
        margin: 0;
    }

.btn-title {
    font-weight: 700;
}

.hide-basket-case {
    display: none;
}

input[type=number].no-input-arrows::-webkit-inner-spin-button,
input[type=number].no-input-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number].no-input-arrows {
    -moz-appearance: textfield;
}

.amount-selector {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
}

    .amount-selector > input[type=number]::-webkit-inner-spin-button,
    .amount-selector > input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .amount-selector > input[type=number] {
        -moz-appearance: textfield;
        width: 60px;
        padding: 5px 20px 5px 15px;
        /*border-radius: 10px;*/
        margin: 0;
        border: 1px solid #eee;
    }

    .amount-selector > .amount-selector-nav {
        position: absolute;
        /*right: -20px;*/
        right: 0;
        height: 100%;
    }

        .amount-selector > .amount-selector-nav > .amount-selector-nav-btn {
            cursor: pointer;
            border-left: 1px solid #eee;
            width: 20px;
            text-align: center;
            /*-webkit-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            transform: translateX(-100%);*/
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -o-user-select: none;
            user-select: none;
            height: 50%;
        }

.basket-case-amount {
    margin: 0 10px 0 0;
}

.basket-case-article {
    margin: 0 10px 0 0;
    font-weight: var(--fontWeight3);
}

.basket-case-price {
    margin: 0 10px 0 0;
}

.basket-case-remove {
    margin: 0;
    color: #CC3B33;
}

.dropdown-menu.show {
    padding: 10px 0;
}

    .dropdown-menu.show > .dropdown-item {
        color: $default-font-color-dark;
        cursor: pointer;
        font-weight: var(--fontWeight3);
        width: 100%;
        height: 100%;
        display: block;
        padding: 5px 20px;
        text-align: right;
        line-height: var(--lineHeight1);
        font-family: $default-font-family-text;
        font-size: $default-font-size;
    }

        .dropdown-menu.show > .dropdown-item:hover, .dropdown-menu.show > .dropdown-item:active {
            background: var(--backgroundColor2);
        }

select, .module-tab-content-body-group > select, select.form-control:not([size]):not([multiple]) {
    background: var(--backgroundColor2);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22caret-down%22%20class%3D%22svg-inline--fa%20fa-caret-down%20fa-w-10%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M31.3%20192h257.3c17.8%200%2026.7%2021.5%2014.1%2034.1L174.1%20354.8c-7.8%207.8-20.5%207.8-28.3%200L17.2%20226.1C4.6%20213.5%2013.5%20192%2031.3%20192z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
    background-position: right 1.1em top 54%, 0 0;
    background-size: .65em auto, 100%;
    background-repeat: no-repeat, repeat;
}

input[type="checkbox"][disabled="disabled"] {
    cursor: not-allowed;
}

.suffix,
.suffix-percent,
.suffix-euro,
.suffix-m2,
.suffix-mW, .suffixm-Wh,
.suffix-W, .suffix-Wh, .suffix-Wp,
.suffix-kW, .suffix-kWh, .suffix-kWp,
.suffix-MW, .suffix-MWh,
.suffix-GW, .suffix-GWh,
.suffix-TW, .suffix-TWh,
.suffix-PW, .suffix-PWh, .suffix-A {
    height: 0;
    position: absolute;
    top: 5px;
    right: 35px;
    
    &.suffix-without-label {
        top: 6px;
    }
}

    .suffix-euro:after {
        content: '€';
    }

    .suffix-percent:after {
        content: '%';
    }

    .suffix-m2:after {
        /* content: 'm²'; */
        content: 'm\00B2';
    }

    .suffix-mW:after {
        content: 'mW';
    }

    .suffix-mWh:after {
        content: 'mWh';
    }

    .suffix-W:after {
        content: 'W';
    }

    .suffix-Wh:after {
        content: 'Wh';
    }

    .suffix-Wp:after {
        content: 'Wp';
    }

    .suffix-kW:after {
        content: 'kW';
    }

    .suffix-kWh:after {
        content: 'kWh';
    }

    .suffix-kWp:after {
        content: 'kWp';
    }

    .suffix-MW:after {
        content: 'MW';
    }

    .suffix-MWh:after {
        content: 'MWh';
    }

    .suffix-GW:after {
        content: 'GW';
    }

    .suffix-GWh:after {
        content: 'GWh';
    }

    .suffix-TW:after {
        content: 'TW';
    }

    .suffix-TWh:after {
        content: 'TWh';
    }

    .suffix-PW:after {
        content: 'PW';
    }

    .suffix-PWh:after {
        content: 'PWh';
    }

.suffix-A:after {
    content: 'A';
}

.message-textarea {
    height: 29px;
    min-height: 29px;
    border-radius: 15px !important;
}

.module-content p a {
    color: var(--buttonColorGreen);
    font-weight: 700;
}

.list-dropdown {
    display: none;
    min-width: 150px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    right: 0;
    background: #FFF;
    -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 0;
    border-radius: 10px;
    z-index: 100;
    top: 100%;
}

    .list-dropdown ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

        .list-dropdown ul li {
            margin: 0;
            padding: 0;
        }

            .list-dropdown ul li:first-child {
                margin-top: 10px;
            }

            .list-dropdown ul li:last-child {
                margin-bottom: 10px;
            }

            .list-dropdown ul li a {
                font-weight: 700;
                width: 100%;
                height: 100%;
                display: block;
                padding: 5px 20px;
                text-align: right;
                color: $default-font-color-dark;
            }

                .list-dropdown ul li a:hover {
                    background: var(--backgroundColor2);
                }

.small-delete-btn {
    border: none;
    margin: 0 !important;
    padding: 0;
    background: var(--buttonColorRed);
    width: 20px;
    height: 20px;
    border-radius: 20px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
    z-index: 100;
}

.small-delete-btn-2 {
    border: none;
    margin: 0 !important;
    padding: 0;
    background: var(--buttonColorRed);
    width: 20px;
    height: 20px;
    border-radius: 20px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30px;
    right: 5px;
    font-size: 10px;
    z-index: 100;
}

.small-edit-btn {
    border: none;
    margin: 0 !important;
    padding: 0;
    background: var(--buttonColorBlue);
    width: 20px;
    height: 20px;
    border-radius: 20px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
    z-index: 100;
}

.small-edit-btn-2 {
    border: none;
    margin: 0 !important;
    padding: 0;
    background: var(--buttonColorBlue);
    width: 20px;
    height: 20px;
    border-radius: 20px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30px;
    right: 5px;
    font-size: 10px;
    z-index: 100;
}

.large-add-btn {
    border: none;
    margin: 0 !important;
    padding: 0;
    background: var(--buttonColorGreen);
    width: 20px;
    height: 20px;
    border-radius: 20px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    /*position: absolute;*/
    top: 5px;
    right: 5px;
    font-size: 10px;
    z-index: 100;
}


/* ---------- 3.2: Button Containers ---------- */
.error-centered-btn {
    position: absolute;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.content-btn-row, .row.content-btn-row {
    margin-bottom: 25px;
}

    .content-btn-row > div:not(.content-btn), .content-btn-row-right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        width: 100%;
    }

.content-btn-row-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    flex-flow: row wrap;
    column-gap: 15px;
    row-gap: 15px;
}

    .content-btn-row-center button, .content-btn-row-center a {
        margin: 0;
    }

.tab-content > .legend-buttons {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0 0 0;
}

.content-masonry .content-btn-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-column: 1 / -1;
    margin: 0;
}


/* ---------- 3.3: Scrollbar ---------- */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;

    @include size(tablet) {
        //width: unset;
        //height: unset;
        width: 0;
        height: 0;
    }
}

::-webkit-scrollbar-track {
    background: var(--backgroundColor2);
    
    @include size(tablet) {
        background: unset;
    }
}

::-webkit-scrollbar-thumb {
    background: var(--backgroundColor3);
    border-radius: $default-border-radius;

    @include size(tablet) {
        background: unset;
        border-radius: unset;
    }
}

    ::-webkit-scrollbar-thumb:hover {
        background: var(--styleColor1);

        @include size(tablet) {
            background: unset;
        }
    }


/* ---------- 3.4: Loading Steps ---------- */

.tab-content {
    .tab-content {
        .setup-panel {
            padding: 0;
        }
    }
}

.module-content-steps {
    padding: var(--padding2) !important;
}

    .module-content-steps > ul {
        list-style: none;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin: 0;
        padding: 15px 0 0 0;
        width: 100%;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

        .module-content-steps > ul > li {
            width: 100%;
            padding: 0 10px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            position: relative;
        }

            .module-content-steps > ul > li::before {
                content: '';
                width: 100%;
                height: 5px;
                background: var(--styleColor3);
                position: absolute;
                top: 10px;
                left: -50%;
            }

            .module-content-steps > ul > li.disabled::before {
                content: '';
                width: 100%;
                height: 5px;
                background: #EEE;
                position: absolute;
                top: 10px;
                left: -50%;
            }

            .module-content-steps > ul > li:first-child::before {
                content: none;
            }

            /*.module-content-steps > ul > li::after {
                content: '';
                width: 50%;
                height: 5px;
                background: #15C39A;
                position: absolute;
                top: 10px;
                right: 0;
            }

            .module-content-steps > ul > li:last-child::after {
                content: none;
            }*/

            .module-content-steps > ul > li > .step-container {
                position: relative;
                z-index: 1;
            }

            .module-content-steps > ul > li.disabled > .step-container {
                cursor: not-allowed;
            }

.module-content-step {
    margin: 0;
    padding: 0;
    font-size: $default-font-size;
    text-align: center;
    position: relative;
    padding: 40px 0 0 0;
}

/*    .module-content-step::before {
        content: '';
        width: 25px;
        height: 25px;
        background: var(--styleColor3);
        position: absolute;
        left: 50%;
        transform: translate(-50%);
         border-radius: var(--roundCorner2);
        top: 0;
    }*/

.step-bullet {
    width: 25px;
    height: 25px;
    background: var(--styleColor3);
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    border-radius: var(--roundCorner2);
    top: 0;
}

.module-content-steps > ul > li.active > .step-container > .step-bullet {
    background: var(--buttonColorGreen);
}

.module-content-steps > ul > li.disabled > .step-container > .step-bullet {
    background: var(--backgroundColor3);
}

.step-title {
    font-size: var(--titleFontSize);
    font-weight: var(--fontWeight4);
    font-family: $default-font-family-headline;
    width: 100%;
    text-align: center;
}

.step-description-container {
    width: 100%;
    margin: 0 0 30px 0;
    text-align: center;
}

.step-description {
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: $default-font-size;
    color: $default-font-color-dark;
}

.module-tab-content-body-group > label.drop-file-label {
    width: 100%;
    height: 150px;
    padding: 0;
    border-radius: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: var(--backgroundColor2);
    border: 5px dashed #15C39A;
}

.module-tab-content-body-group > input.drop-file {
    /*display: none;*/
}

.drag-area {
    border: 4px dashed #15C39A;
    min-height: 150px;
    height: -webkit-fit-content;
    height: fit-content;
    height: -moz-fit-content;
    width: 100%;
    border-radius: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

    .drag-area.active {
        border: 5px solid #15C39A;
        background: var(--backgroundColor2);
    }

        .drag-area.active h5, .drag-area.active span, .drag-area.active div {
            pointer-events: none;
        }

    .drag-area.error {
        border: 5px solid #c31515;
        background: var(--backgroundColor2);
    }

    /*.drag-area .icon {
        font-size: 30px;
        color: #15C39A;
    }*/

    .drag-area h5 {
        font-size: var(--titleFontSize);
        margin: 0;
    }

        .drag-area h5 i {
            margin: 0 5px 0 0;
        }

    .drag-area h4 {
        font-size: var(--legendHeadFontSize);
        font-weight: var(--fontWeight3);
        margin: 0;
    }

    .drag-area p {
        margin: 0;
    }

    .drag-area span {
        font-size: var(--legendHeadFontSize);
        margin: 0 0 0 20px;
        font-weight: var(--fontWeight2);
    }

    .drag-area button {
        margin: 0 0 0 20px;
    }

.fileName-Container {
    width: 100%;
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    text-align: center;
}

/* ---------- 3.5: Daterange Picker ---------- */

.daterangepicker {
    border-radius: $default-border-radius;
    border: none;
    width: -webkit-fit-content;
    width: fit-content;
    width: -moz-fit-content;
    background: var(--backgroundColor1);
    -webkit-box-shadow: var(--shadow3);
    box-shadow: var(--shadow3);
    margin: 0;
    font-family: unset;
}

    .daterangepicker:before, .daterangepicker:after {
        display: none;
    }

    .daterangepicker .ranges {
        border-radius: unset;
    }

        .daterangepicker .ranges ul {
            margin: 0;
            width: unset;
            border-radius: unset;
        }

        .daterangepicker .ranges li {
            text-align: right;
            line-height: var(--lineHeight1);
            font-size: $default-font-size;
            color: $default-font-color-dark;
            cursor: pointer;
            font-weight: var(--fontWeight3);
            width: 100%;
            height: 100%;
            display: block;
            padding: 5px 20px;
        }

    .daterangepicker td.in-range {
        background-color: var(--styleColor3);
    }

    .daterangepicker td.available:hover, .daterangepicker th.available:hover {
        background-color: var(--buttonColorGreen);
        color: $default-font-color-dark;
    }

    .daterangepicker .ranges li.active {
        background-color: var(--styleColor2);
    }

    .daterangepicker .ranges li:first-child {
        margin-top: var(--marginTop4);
    }

    .daterangepicker .ranges li:last-child {
        margin-bottom: var(--marginBottom4);
    }

    .daterangepicker td.active, .daterangepicker td.active:hover {
        background-color: var(--styleColor2);
    }


/* ---------- 3.6: FullCalender ---------- */

.fc-timegrid-event {
    border-color: var(--styleColor3) !important;
    background-color: var(--styleColor3) !important;
}

.fc-event-main {
    color: $default-font-color-dark !important;
}

.fc-timegrid-event .fc-event-time {
    font-size: 11px !important;
    /*word-break: break-word !important;*/
    white-space: unset !important;
    font-weight: 700 !important;
}

.fc .fc-timegrid-col.fc-day-today {
    background-color: var(--backgroundColor2) !important;
}

/*.fc-day-grid-event > .fc-content {
    white-space: unset !important;
}*/


/* ---------- 3.7: Tooltips ---------- */

.content-tooltip {
    border: none;
    border-radius: $default-border-radius;
    margin: 0;
    background: var(--backgroundColor1);
    -webkit-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
    position: absolute;
    min-width: 200px;
    z-index: 200;
    padding: var(--padding2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    row-gap: 15px;
}

    .content-tooltip .content-tooltip-header {
        width: 100%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .content-tooltip .content-tooltip-content {
        width: 100%;
    }

    .content-tooltip h5 {
        width: 100%;
        font-weight: var(--fontWeight3);
        font-family: $default-font-family-headline;
        font-size: var(--titleFontSize);
        margin: 0;
    }

    .content-tooltip .content-tooltip-content h5 {
        margin: 0 0 10px 0;
    }

    .content-tooltip .content-tooltip-content p {
        width: 100%;
        font-family: $default-font-family-text;
        font-size: $default-font-size;
        margin: 0;
    }

    .content-tooltip .content-tooltip-buttons {
        width: 100%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

.content-tooltip-top::before, .content-tooltip-bottom::before, .content-tooltip-left::before, .content-tooltip-right::before {
    display: none;
}

@supports ((-webkit-clip-path: inset(50%)) or (clip-path: inset(50%))) {
    .content-tooltip-top.content-tooltip-arrow::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        right: 50%;
        bottom: -20px;
        -webkit-transform: translate(50%, -50%) rotate( 315deg );
        -ms-transform: translate(50%, -50%) rotate( 315deg );
        transform: translate(50%, -50%) rotate( 315deg );
        background-color: inherit;
        -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        border-radius: 0 0 0 5px;
    }

    .content-tooltip-bottom.content-tooltip-arrow::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        right: 50%;
        top: 0px;
        -webkit-transform: translate(50%, -50%) rotate( 135deg );
        -ms-transform: translate(50%, -50%) rotate( 135deg );
        transform: translate(50%, -50%) rotate( 135deg );
        background-color: inherit;
        -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        border-radius: 0 0 0 5px;
    }

    .content-tooltip-left.content-tooltip-arrow::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        right: -10px;
        top: 50%;
        -webkit-transform: translate(0, -50%) rotate(225deg);
        -ms-transform: translate(0, -50%) rotate(225deg);
        transform: translate(0, -50%) rotate(225deg);
        background-color: inherit;
        -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        border-radius: 0 0 0 5px;
    }

    .content-tooltip-right.content-tooltip-arrow::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        left: -10px;
        top: 50%;
        -webkit-transform: translate(0, -50%) rotate(45deg);
        -ms-transform: translate(0, -50%) rotate(45deg);
        transform: translate(0, -50%) rotate(45deg);
        background-color: inherit;
        -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        border-radius: 0 0 0 5px;
    }
}

.content-tooltip-top, .content-tooltip-bottom, .content-tooltip-left, .content-tooltip-right {
    width: 300px;
}

.content-tooltip-center {
    width: clamp(300px, 50%, 500px);
}

.hideTooltip {
    display: none;
}


/* ---------- 3.9: Spinner ---------- */
.windmillSpinnerContainer {
    width: 200px;
    height: 200px;
    position: fixed;
    bottom: 0;
    left: 100px;
}

.windmillSpinnerContainer-hide {
    display: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

.windmillSpinnerContainer > .loaderBackground {
    width: 100%;
    height: 100%;
    background-image: url(../../images/loader/windmillLoader1.svg);
    position: absolute;
}

.windmillSpinnerContainer > .loaderSpinner {
    width: 100%;
    height: 100%;
    background-image: url(../../images/loader/windmillLoader2.svg);
    position: absolute;
    /*animation: spin 2s linear infinite;*/
}


.page-spinner {
    position: fixed;
    bottom: 50px;
    left: 100px;
}

    .page-spinner > p {
        margin: 0;
        position: relative;
        left: 30px;
    }

.page-spinner2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

    .page-spinner2 > .spinner-border {
        /*color: green;*/
        height: 50px;
        width: 50px;
        border-width: 5px;
    }

.page-spinner-hide {
    display: none;
}


/* ---------- 3.10: SWAL2 ---------- */

//.swal2-modal {
//    background: var(--backgroundColor1) !important;
//    border: none !important;
//    border-radius: $default-border-radius !important;
//    padding: var(--padding2) !important;
//}

//.swal2-title {
//    font-family: $default-font-family-headline !important;
//    font-size: var(--titleFontSize) !important;
//    font-weight: var(--fontWeight4) !important;
//    color: $default-font-color-dark !important;
//    width: 100%;
//    padding: 0 !important;
//}

//.swal2-header {
//    padding: 0 !important;
//    margin: 0 !important;
//}
//
//.swal2-content {
//    font-family: $default-font-family-text !important;
//    font-size: $default-font-size !important;
//    font-weight: 400 !important;
//    color: $default-font-color-dark !important;
//    padding: 0 !important;
//    margin: 0 0 20px 0 !important;
//    display: flex !important;
//    flex-flow: row wrap !important;
//}
//
//.swal2-actions {
//    column-gap: 15px !important;
//    margin: 0 !important;
//}
//
//button.swal2-styled {
//    color: var(--textColorLight) !important;
//    border-radius: var(--roundCorner2) !important;
//    border: none !important;
//    white-space: nowrap !important;
//    margin: 0 !important;
//    padding: var(--padding1) !important;
//    cursor: pointer !important;
//    font-size: var(--inputFontSize) !important;
//    -webkit-user-select: none !important;
//    -moz-user-select: none !important;
//    -ms-user-select: none !important;
//    user-select: none !important;
//}
//
//    button.swal2-styled:hover {
//        color: var(--textColorLight) !important;
//    }
//
//    button.swal2-styled:first-child {
//        margin-left: 0 !important;
//    }
//
//.swal2-confirm {
//    background: var(--buttonColorGreen);
//    -webkit-box-shadow: 0 5px 7px 0 rgba(21,195,154,0.3);
//    box-shadow: 0 5px 7px 0 rgba(21,195,154,0.3);
//}
//
//    .swal2-confirm:hover {
//        -webkit-box-shadow: rgba(21,195,154,0.3) 0 4px 12px;
//        box-shadow: rgba(21,195,154,0.3) 0 4px 12px !important;
//        -webkit-box-shadow: rgba(21,195,154, 0.16) 0 10px 20px, rgba(21,195,154,0.3) 0 6px 6px;
//        box-shadow: rgba(21,195,154, 0.16) 0 10px 20px, rgba(21,195,154,0.3) 0 6px 6px;
//    }
//
//    .swal2-confirm:active {
//        -webkit-box-shadow: rgba(21,195,154, 0.12) 0 1px 3px, rgba(21,195,154, 0.24) 0 1px 2px !important;
//        box-shadow: rgba(21,195,154, 0.12) 0 1px 3px, rgba(21,195,154, 0.24) 0 1px 2px !important;
//    }
//
//.swal2-deny {
//    background: var(--buttonColorRed) !important;
//    -webkit-box-shadow: 0 5px 7px 0 rgba(204,59,51,0.3) !important;
//    box-shadow: 0 5px 7px 0 rgba(204,59,51,0.3) !important;
//}
//
//    .swal2-deny:hover {
//        -webkit-box-shadow: rgba(204,59,51,0.3) 0 4px 12px !important;
//        box-shadow: rgba(204,59,51,0.3) 0 4px 12px !important;
//        -webkit-box-shadow: rgba(204,59,51, 0.16) 0 10px 20px, rgba(204,59,51,0.3) 0 6px 6px !important;
//        box-shadow: rgba(204,59,51, 0.16) 0 10px 20px, rgba(204,59,51,0.3) 0 6px 6px !important;
//    }
//
//    .swal2-deny:active {
//        -webkit-box-shadow: rgba(204,59,51, 0.12) 0 1px 3px, rgba(204,59,51, 0.24) 0 1px 2px !important;
//        box-shadow: rgba(204,59,51, 0.12) 0 1px 3px, rgba(204,59,51, 0.24) 0 1px 2px !important;
//    }
//
//.swal2-cancel {
//    background: #AAA !important;
//    -webkit-box-shadow: 0 5px 7px 0 rgba(170,170,170,0.3) !important;
//    box-shadow: 0 5px 7px 0 rgba(170,170,170,0.3) !important;
//}
//
//    .swal2-cancel:hover {
//        -webkit-box-shadow: rgba(170,170,170,0.3) 0 4px 12px !important;
//        box-shadow: rgba(170,170,170,0.3) 0 4px 12px !important;
//        -webkit-box-shadow: rgba(170,170,170, 0.16) 0 10px 20px, rgba(170,170,170,0.3) 0 6px 6px !important;
//        box-shadow: rgba(170,170,170, 0.16) 0 10px 20px, rgba(170,170,170,0.3) 0 6px 6px !important;
//    }
//
//    .swal2-cancel:active {
//        -webkit-box-shadow: rgba(170,170,170, 0.12) 0 1px 3px, rgba(170,170,170, 0.24) 0 1px 2px !important;
//        box-shadow: rgba(170,170,170, 0.12) 0 1px 3px, rgba(170,170,170, 0.24) 0 1px 2px !important;
//    }
//
//.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
//    background-color: var(--buttonColorRed) !important;
//}
//
//.swal2-icon.swal2-error {
//    border-color: var(--buttonColorRed) !important;
//    color: var(--buttonColorRed) !important;
//}
//
//.swal2-input {
//    font-weight: var(--fontWeight2) !important;
//    font-size: var(--inputFontSize) !important;
//    width: 100% !important;
//    height: unset !important;
//    float: left !important;
//    border: none !important;
//    color: $default-font-color-dark !important;
//    padding: var(--padding4) !important;
//    margin: 0 !important;
//    border-radius: var(--roundCorner2) !important;
//    background: var(--backgroundColor2) !important;
//    box-shadow: unset !important;
//}
//
//.swal2-select {
//    font-weight: var(--fontWeight2) !important;
//    font-size: var(--inputFontSize) !important;
//    width: 100% !important;
//    height: unset !important;
//    float: left !important;
//    border: none !important;
//    color: $default-font-color-dark !important;
//    padding: var(--padding4) !important;
//    margin: 0 !important;
//    border-radius: var(--roundCorner2) !important;
//    background: var(--backgroundColor2) !important;
//    box-shadow: unset !important;
//}
//
//.swal2-file {
//    border: none !important;
//    border-radius: 0 !important;
//    box-shadow: unset !important;
//    width: fit-content !important;
//}
//
//.swal2-html-container {
//    width: 100% !important;
//}
//
//.swal2-range {
//    width: 100% !important;
//}
//
//.swal2-validation-message {
//    width: 100% !important;
//}

/* ---------- 3.11: Bootstrap Tags ---------- */

.bootstrap-tagsinput {
    /*font-weight: var(--fontWeight2);
    font-size: var(--inputFontSize);
    width: 100%;
    float: left !important;
    border: none;
    color: $default-font-color-dark;
    padding: var(--inputPadding);
    margin: 0;
    border-radius: var(--roundCorner2);
    background: var(--backgroundColor2);
    display: flex;*/

    background: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

    .bootstrap-tagsinput > .twitter-typeahead {
        width: 100%;
    }

        .bootstrap-tagsinput > input, .bootstrap-tagsinput > .twitter-typeahead > input {
            /*border: none;
        width: 100%;
        min-width: 150px;
        padding: 0 5px;
        color: $default-font-color-dark;
        background: none;*/

            font-size: var(--inputFontSize);
            width: 100%;
            float: left !important;
            border: none;
            color: $default-font-color-dark;
            padding: var(--inputPadding);
            margin: 0;
            border-radius: var(--roundCorner2);
            background: var(--backgroundColor2) !important;
            display: flex;
            flex-wrap: wrap;
        }

        .bootstrap-tagsinput > .twitter-typeahead > .tt-hint {
            opacity: 0.5 !important;
        }

    .bootstrap-tagsinput .tag {
        line-height: unset;
        line-height: unset;
        color: var(--textColorLight);
        /*background: none;*/
        padding: 2px 10px;
        margin: 0 0 10px 15px;
        display: inline-flex;
        border-radius: 10px;
        background: var(--buttonColorBlue);
    }

.tt-menu {
    background: #FFF;
    -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 0;
    border-radius: 10px;
    -webkit-transform: translate(0, 11px);
    -ms-transform: translate(0, 11px);
    transform: translate(0, 11px);
    border: none;
}

    .tt-menu .tt-dataset.tt-dataset-value {
        padding: 10px 0;
    }

        .tt-menu .tt-dataset.tt-dataset-value .tt-suggestion {
            padding: 3px 20px;
            text-align: left;
            color: $default-font-color-dark;
            cursor: pointer;
            font-weight: 700;
            font-family: $default-font-family-text;
            font-size: $default-font-size;
        }

            .tt-menu .tt-dataset.tt-dataset-value > .tt-cursor, .tt-menu .tt-dataset.tt-dataset-value .tt-suggestion:hover {
                color: $default-font-color-dark;
                background: var(--backgroundColor2);
            }
/* ---------- 3.12: Background ---------- */

.background-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    z-index: 199;
}

.show-background-overlay {
    display: block;
}


/* ---------- 3.13: Autocomplete ---------- */

.autocomplete-items {
    padding: 15px 0;
    margin: 0;
    list-style: none;
    position: absolute;
    background: var(--backgroundColor1);
    width: 100%;
    /*top: 29px;*/
    top: unset;
    left: 0;
    box-shadow: var(--shadow2);
    border: none;
    border-radius: $default-border-radius;
    z-index: 10;
}

.autocomplete-item {
    padding: 5px 15px;
    cursor: pointer;
}

    .autocomplete-item:hover {
        background: var(--backgroundColor2);
    }

    .autocomplete-item.autocomplete-active {
        background: var(--backgroundColor2);
    }


/* --------------- 5: Menu --------------- */
/* ---------- 5.1: Settings Menu ---------- */
.settings-content-groups {
    /*overflow-y: auto;*/
    overflow-y: visible;
    /*height: calc(100% - 53px);*/
    margin: var(--margin5);
    max-height: calc(100vh - 469px);
}

.settings-content-group {
    margin: var(--margin4);
}

    .settings-content-group:last-child {
        margin: 0;
    }

    .settings-content-group > a, .settings-content-group > button, .settings-content-group > .settings-content-group-title {
        position: relative;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        background: var(--backgroundColor2);
        border-radius: $default-border-radius;
        padding: var(--padding1);
        border: none;
        column-gap: 10px;
    }

        .settings-content-group > a:hover, .settings-content-group > button:hover, .settings-content-group > .settings-content-group-title:hover {
            background: var(--backgroundColor3);
        }

        .settings-content-group > .settings-content-group-title > a, .settings-content-group > .settings-content-group-title > button {
            width: 100%;
        }

            .settings-content-group > a[disabled], .settings-content-group > button[disabled], .settings-content-group > .settings-content-group-title > a[disabled], .settings-content-group > .settings-content-group-title > button[disabled] {
                cursor: not-allowed;
            }

            .settings-content-group > a > h3, .settings-content-group > button > h3, .settings-content-group > .settings-content-group-title > a > h3, .settings-content-group > .settings-content-group-title > button > h3 {
                margin: 0;
                font-family: $default-font-family-headline;
                font-size: var(--legendHeadFontSize);
                font-weight: var(--fontWeight4);
                width: 100%;
                text-align: left;
            }

    .settings-content-group .settings-content-group-title .module-options, .settings-content-group .settings-content-group-title .settings-content-group-colapse {
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        width: unset;
        height: unset;
        position: relative;
        top: 1px;
    }

    .settings-content-group .settings-content-group-title .settings-content-group-colapse {
        width: 25px;
    }

.settings-content-group-title.active {
    background-color: var(--backgroundColor3);
}

.settings-content-group-items {
    display: none;
}

.settings-content-group > ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
}

    .settings-content-group > ul > li {
        width: 100%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: var(--padding5);
        height: 20px;
        position: relative;
    }

        .settings-content-group > ul > li:first-child {
            margin-top: 5px;
        }

        .settings-content-group > ul > li:last-child {
            margin-bottom: var(--marginBottom5);
        }

        .settings-content-group > ul > li:hover, .settings-content-group > ul > li.active {
            background: var(--backgroundColor2);
            border-radius: $default-border-radius;
        }

        .settings-content-group > ul > li > a {
            width: 100%;
            height: 100%;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-weight: var(--fontWeight2);
            overflow: hidden;
            height: 14px;
            color: $default-font-color-dark;
        }

            .settings-content-group > ul > li > a > h4 {
                width: 100%;
                margin: 0;
                font-size: unset;
                font-weight: var(--fontWeight2);
            }

        .settings-content-group > ul > li > button > i {
            font-size: $default-font-size;
        }

    .settings-content-group > ul > .legend-content-group-body-item-tab-1 {
        padding: 0 20px 0 44px;
    }

.tab-content-configuration {
    width: 100%;
}

    .tab-content-configuration .module-content-btns, .tab-pane .module-content-btns {
        display: flex;
        justify-content: flex-end;
    }




/* --------------- 6: Body --------------- */
/* ---------- 6.1: Correctional ---------- */
/* Browsers */
[type="button"],
[type="submit"],
button {
    -webkit-appearance: none;
}


/* Bootstrap */

.pcoded-content {
    padding: 0;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding: 0;
}

.row {
    margin: 0px;
}

.loader-bg .loader-track .loader-fill:after, .loader-bg .loader-track .loader-fill:before {
    background: var(--styleColor1);
}

/* SWAL */

html > body.swal2-height-auto {
    height: 100vh !important;
}

body > .swal2-container {
    z-index: 2000;
}

.hr-container {
    padding: 0 0 15px 0;
}

hr {
    margin: 0;
    border: 0;
    border-bottom: var(--borderBottom1);
    width: 100%;
}

b, strong {
    font-weight: 700;
}

/* ---------- 6.2: Modules ---------- */
/* ----- 6.2.1: Groups ----- */
#addNewGroup {
    display: none;
}

.nogroup {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: none;
    margin-bottom: 0;
    padding-top: 0;
}

/* ----- 6.2.2: Modules General ----- */

.module-container {
    border: none;
    border-radius: $default-module-border-radius;
    width: 100%;
    height: fit-content;
    height: -moz-fit-content;
    /*margin-bottom: var(--marginBottom1);*/
    background: var(--backgroundColor1);
    -webkit-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
    position: relative;
    
    &.equalHeight {
        height: 100%;
        
        .module-content {
            height: calc(100% - var(--headerHeight1));
        }
    }

    dl {
        width: 100%;
        margin: 0;
        display: flex;
        flex-flow: row wrap;
        gap: 10px;
        line-break: anywhere;
        
        &.highlight {
            background: var(--styleColor3);
            color: var(--textColorDark);
            padding: 10px;
            border-radius: 5px;
        }

        dt, dd {
            width: max-content;
            margin: 0;
            padding: 0;
        }

        dt {
            font-weight: 700;
        }

        dd {
            text-align: right;
            margin-left: auto;
        }
    }
}

    .module-container .module-container:not(.module-container .modal .module-container) {
        border-radius: 0;
        background: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .module-container .module-container .module-header:not(.module-container .modal .module-container .module-header) {
        display: none;
    }

    .module-container .module-container .module-content .tab-content:not(.module-container .modal .module-container .module-content .tab-content) {
        padding: 0;
    }

.module-container-2 {
    width: 100%;
}

.module-container-grid {
    grid-column: 1 / -1;
}

.module-container-grid-span2 {
    grid-column: span 2;
}

.module-container-grid-span2-1 {
    grid-column: span 2;
}

.module-container-contentReplaceables {
    width: 100%;
}

.module-header {
    height: var(--headerHeight1);
    border-bottom: var(--borderBottom1);
    text-align: center;
    margin: var(--marginSides1);
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: relative;
    border-top-left-radius: $default-module-border-radius-top-left;
    border-top-right-radius: $default-module-border-radius-top-right;
}

    .module-header > .module-options, .module-header > [onclick="moduleOptions(this)"] {
        position: absolute;
        top: calc(50% + 1px);
        right: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        padding: 0 6px;
    }

.module-container-2 > .module-header {
    height: 80px;
    padding: 15px 0;
    margin: var(--marginSides1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

    .module-container-2 > .module-header > .module-header-text {
        height: auto;
        width: 60%;
    }

        .module-container-2 > .module-header > .module-header-text > h5 {
            position: unset;
            top: unset;
            left: unset;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            max-width: unset;
            text-align: left;
            white-space: unset;
            margin: 0 0 8px 0;
        }

        .module-container-2 > .module-header > .module-header-text > p {
            text-align: left;
            margin: 0;
        }

    .module-container-2 > .module-header > .module-header-logo {
        height: auto;
        width: 40%;
    }

.module-icon {
    font-size: var(--caretSize1);
    color: $default-font-color-dark;
    margin-right: 0;
}

/*.legend-item-group .module-icon {
    margin-left: 10px;
}*/

.module-colapse {
    width: var(--caretSizeContainer1);
    position: absolute;
    top: calc(50% + 1px);
    left: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    border: none;
    background: none;
    cursor: pointer;
}

//.module-options-dropdown {
//    display: none;
//    background: var(--backgroundColor1);
//    color: $default-font-color-dark;
//    padding: 12px 16px;
//    border: none;
//    border-radius: $default-border-radius;
//    -webkit-box-shadow: var(--shadow2);
//    box-shadow: var(--shadow2);
//    position: absolute;
//    right: 5px;
//    top: 5px;
//    /*z-index: 30;*/
//}
//
//    .module-options-dropdown > ul {
//        list-style: none;
//        padding: 0;
//        margin: 0;
//    }
//
//        .module-options-dropdown > ul > li {
//            text-align: right;
//            line-height: 25px;
//            font-weight: var(--fontWeight3);
//            color: $default-font-color-dark;
//            cursor: pointer;
//        }
//
//            .module-options-dropdown > ul > li > a {
//                color: $default-font-color-dark;
//            }
//
//    .module-options-dropdown > div {
//        width: 100%;
//        height: 26px;
//        margin-bottom: 8px;
//        border-bottom: var(--borderBottom1);
//    }
//
//        .module-options-dropdown > div > * {
//            display: block;
//            float: right;
//        }

.module-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom-left-radius: $default-module-border-radius-bottom-left;
    border-bottom-right-radius: $default-module-border-radius-bottom-right;
    /*height: calc(100% - 51px);*/ /*Weg omdat dit voor problemen zorgt in Safari*/
    flex-flow: row wrap;
    /*overflow: hidden;*/
}

.module-content-multiple {
    margin: var(--margin1);
}

    .module-content-multiple > div:not(:first-child) {
        margin-top: var(--marginTop1);
    }

.module-content-padded {
    margin: 10px 25px;
}

.module-block {
    display: block;
}

.nav-link {
    padding: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.form-group {
    width: 100%;
    margin-bottom: var(--marginBottom3);
    display: flex;
    gap: 5px;
    flex-flow: column nowrap;

    //&:last-child {
    //    margin-bottom: 0;
    //}

    &.center {
        flex-flow: column wrap;
        align-items: center;
        /*line-height: $default-menu-lineHeight;*/

        .item {
            display: flex;
            gap: 10px;

            &:not(:last-child) {
                margin-bottom: 5px;
            }

            * {
                margin: 0;
            }
        }
    }

    hr {
        margin-bottom: 15px;
    }
    
    iframe {
        min-height: 40vh;
    }
    
    &.form-group-checkbox {
        flex-direction: row-reverse;
        
        .module-tab-content-body-group {
            //width: fit-content;
            width: 100%;
        }

        .module-tab-content-title {
            padding-left: 0;
        }
        
        .input-wrapper {
            flex-flow: row-reverse nowrap;
        }
    }
}

.form-group-center {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-column-gap: 25px;
    -moz-column-gap: 25px;
    column-gap: 25px;
    flex-flow: unset;
}

.form-content-half {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.form-group-screenshot-hide {
    display: none;
}

.AddressPartial {
    width: 100%;
}

.accountTypeSelector {
    background: var(--styleColor3);
    padding: 10px;
    color: #333;
    font-weight: 700;
    font-size: 1.3em;
    border-radius: 10px;
}

.module-content-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.module-full-row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}

.module-content-info {
    margin: 0 0 40px 0;
}

    .module-content-info:last-child {
        margin: 0 0 15px 0;
    }

    .module-content-info h5 {
        text-align: left;
        margin: 0 0 15px 0;
    }

    .module-content-info p {
        text-align: left;
        margin: 0 0 15px 0;
    }

.module-content-btnOption {
    margin: 0 0 15px 0;
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    align-items: center;
    background: var(--backgroundColor2);
    border-radius: 10px;
    padding: 15px;
}

    .module-content-btnOption:last-child {
        margin: 0 0 15px 0;
    }

    .module-content-btnOption h5 {
        text-align: center;
        margin: 0 0 15px 0;
        width: 100%;
    }

    .module-content-btnOption p {
        text-align: center;
        margin: 0 0 15px 0;
        width: 100%;
    }

    .module-content-btnOption a, .module-content-btnOption button {
        /*margin: 0 0 15px 0;*/
        margin: 0;
        width: fit-content;
    }

.module-content-section {
    width: 100%;
    margin: 0 0 40px 0;
}

.module-content-section2 {
    width: 100%;
    padding: 0;
    margin-bottom: 25px;
}

    .module-content-section2 > h5 {
        width: 100%;
        text-align: center;
        margin: 0 0 15px 0;
    }

    .module-content-section2 > p {
        width: 100%;
        text-align: center;
        margin: 0 0 15px 0;
    }

    .module-content-section:last-child, .module-content-section2:last-child {
        margin: 0;
    }

.module-content-section h5 {
    width: 100%;
    margin: 0 0 15px 0;
    text-align: center;
}

.module-content-half {
    width: 100%;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}

hr.form-group {
    margin: 0 0 5px 0 !important;
}

.module-content-half-withLeftColumn.form-group {
    display: grid;
    grid-template-columns: repeat(7, minmax(30px, 1fr));
    margin: 0 0 5px 0 !important;
}

.module-content-half-withLeftColumn .form-group-group p {
    margin: 0;
}

.module-content-half-withLeftColumn .form-group-group:nth-child(1) {
    /*position: relative;
        top: 16px;
        height: 29px;
        display: grid;
        place-items: center;*/
    grid-column: 1 / 2;
}

.module-content-half-withLeftColumn .form-group-group:nth-child(2) {
    grid-column: 2 / 5;
}

.module-content-half-withLeftColumn .form-group-group:nth-child(3) {
    grid-column: 5 / 8;
}

    .module-content-half-withLeftColumn .form-group-group:nth-child(3) p {
        text-align: right;
    }

        .module-content-half-withLeftColumn .form-group-group:nth-child(3) p:before {
            content: "€ ";
        }

.module-content-info-groups {
    padding: 15px;
    @include gridColumns;
    width: 100%;
    gap: 15px;
}

.module-content-info-group {
    /*border: var(--borderBottom1);*/
    border: 5px solid #F5F5F5;
    padding: 15px 5px;
    border-radius: 10px;
}

    .module-content-info-group h5 {
        padding: 0 10px;
    }

    .module-content-info-group p {
        margin: 0;
        width: 100%;
    }

        .module-content-info-group p:nth-child(1) {
            font-weight: var(--fontWeight3);
        }

    .module-content-info-group .info-group-content-item {
        width: 100%;
        display: flex;
        column-gap: 15px;
        padding: 2px 10px;
    }

        .module-content-info-group .info-group-content-item:nth-child(2n) {
            background: var(--backgroundColor2);
            border-radius: 10px;
        }

    .module-content-info-group #totalCostPH {
        padding: 0 10px;
    }

.module-content-inputs-rows {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    gap: 15px;
    width: 100%;
    padding: 15px 15px 0 15px;
}

    .module-content-inputs-rows .form-group {
        margin: 0 !important;
    }


.module-content-values {
    width: 100%;
    padding: 0;
    margin: 0;
}

    .module-content-values > li {
        list-style: none;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-column-gap: 10px;
        -moz-column-gap: 10px;
        column-gap: 10px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 5px 15px;
    }

        .module-content-values > li:nth-child(2n+1) {
            background: var(--backgroundColor2);
            border-radius: 10px;
        }

        .module-content-values > li > p {
            margin: 0;
            width: calc(50% - 5px);
            overflow-wrap: anywhere;
            
            &:nth-child(2) {
                text-align: right;
            }
        }

            .module-content-values > li > p:first-child {
                font-weight: var(--fontWeight3);
            }

.asset-title {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    padding: 0 15px 15px 15px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

    .asset-title > h5 {
        margin: 0;
        padding: 0;
    }

    .asset-title > i {
        margin: 0 10px 0 0;
        padding: 0;
        font-size: 17px;
        position: relative;
        top: -1px;
    }

.module-content-spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.module-content-spinner-hide {
    display: none;
}

.module-content > form {
    width: 100%;
    height: fit-content;
}

.module-form-input {
    margin: 0;
    display: inline-flex;
    align-items: center;
    column-gap: 10px;
}

    .module-form-input label {
        margin: 0;
    }

.module-tab-content-images {
    display: flex;
    justify-content: center;
    width: 100%;
    column-gap: 20px;
    /*margin: 0 0 15px 0;*/
}

.module-tab-content-imageContainer {
    position: relative;
    border: 1px solid rgba(102, 102, 102, 0.25);
    border-radius: 10px;
    overflow: hidden;
    min-width: 100px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .module-tab-content-imageContainer img {
        max-width: 100%;
        max-height: 200px;
    }

    .module-tab-content-imageContainer p {
        padding: 15px;
        margin: 0;
    }

.module-tab-content-imageUpload {
    width: 100%;
}

.hideUpload {
    display: none;
}

.content-column-2 {
    width: calc(20% - ((50px * 4) / 5));
}

.module-content > .module-content-item:not(:nth-child(1)) {
    padding-top: 0;
}

.module-content .module-content-item hr {
    margin-top: 15px;
    width: 100%;
    flex: 1 1 auto;
}

.module-content-tab-image-preview {
    width: 100%;
    padding: var(--padding2);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

    .module-content-tab-image-preview > img {
        width: 100%;
        border-radius: 10px;
        max-height: 500px;
        object-fit: contain;
    }

.module-content-tab-image-list-container {
    width: 100%;
    padding: var(--padding2);
    display: flex;
    position: relative;
}

.module-content-tab-image-list {
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    column-gap: 15px;
}

    .module-content-tab-image-list li {
        width: calc(25% - ((15px * 3) / 4));
        overflow: hidden;
        margin: 0;
        justify-content: center;
        align-items: center;
        display: none;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

        .module-content-tab-image-list li.show {
            display: flex;
        }

        .module-content-tab-image-list li img {
            width: 100%;
            border-radius: 10px;
            max-height: 100px;
            object-fit: cover;
        }

.module-content-tab-image-list-nav {
    width: 80px;
}

    .module-content-tab-image-list-nav .image-nav {
        font-size: 40px;
        color: $default-font-color-dark;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 15px;
    }

        .module-content-tab-image-list-nav .image-nav.hide {
            display: none;
        }

        .module-content-tab-image-list-nav .image-nav.image-previous {
            justify-content: flex-end;
        }

        .module-content-tab-image-list-nav .image-nav.image-next {
            justify-content: flex-start;
        }

.module-content-tab-product-variant-list-container {
    width: 100%;
    padding: var(--padding2);
}

.module-content-tab-product-variant-list {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style: none;
    column-gap: 15px;
}

    .module-content-tab-product-variant-list li {
        max-width: 200px;
        background: var(--backgroundColor2);
        cursor: pointer;
        padding: var(--padding1);
        border-radius: 10px;
    }

        .module-content-tab-product-variant-list li.selected {
            background: var(--buttonColorGreen);
            color: var(--textColorLight);
        }

.module-content-tab-product-price-container {
    width: 100%;
    padding: var(--padding2);
    display: flex;
    flex-wrap: wrap;
}


.module-content-tab-product-price-column {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    row-gap: 5px;
    flex: 1;
}

    .module-content-tab-product-price-column h3 {
        margin: 0;
        width: 100%;
        text-align: left;
    }

    .module-content-tab-product-price-column h4 {
        margin: 0;
        width: 100%;
        text-align: left;
    }

    .module-content-tab-product-price-column p {
        margin: 0;
    }

.module-content-tab-product-description-container {
    width: 100%;
    padding: var(--padding2);
}

    .module-content-tab-product-description-container p {
        margin: 0;
    }

.module-content-tab-product-priceSpecifications-container {
    width: 100%;
    padding: var(--padding2);
    @include gridColumns;
    gap: 15px;
}

    .module-content-tab-product-priceSpecifications-container .productPrice-specification {
        width: 100%;
    }

.module-content-tab-product-specifications-container {
    width: 100%;
    padding: var(--padding2);
}

.module-content-tab-product-specifications-list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

    .module-content-tab-product-specifications-list li {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 7px 15px;
        border-radius: 10px;
    }

        .module-content-tab-product-specifications-list li:nth-child(2n+1) {
            background: var(--backgroundColor2);
        }

        .module-content-tab-product-specifications-list li h4 {
            margin: 0;
        }

        .module-content-tab-product-specifications-list li p {
            margin: 0 0 0 auto;
        }

.module-content-tab-order-text-container {
    width: 100%;
    padding: var(--padding2);
}

    .module-content-tab-order-text-container h5 {
        width: 100%;
        text-align: center;
    }

    .module-content-tab-order-text-container p {
        width: 100%;
        text-align: center;
        margin: 0 0 15px 0;
    }

.module-content-tab-order-Address-container {
    width: 100%;
    display: flex;
}

    .module-content-tab-order-Address-container .module-content-tab-order-Address-column {
        width: 100%;
        padding: var(--padding2);
    }

        .module-content-tab-order-Address-container .module-content-tab-order-Address-column h5 {
            width: 100%;
            text-align: left;
        }

        .module-content-tab-order-Address-container .module-content-tab-order-Address-column p {
            width: 100%;
            text-align: left;
            margin: 0 0 0.3em 0;
        }

.module-content-tab-order-shipping-container {
    width: 100%;
    padding: var(--padding2);
}

    .module-content-tab-order-shipping-container h5 {
        width: 100%;
        text-align: left;
    }

    .module-content-tab-order-shipping-container p {
        width: 100%;
        text-align: left;
        margin: 0 0 0.3em 0;
    }

.module-content-tab-order-information-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

    .module-content-tab-order-information-container .module-content-tab-order-information-box {
        padding: 15px;
        width: clamp(200px, 50%, 250px);
        flex-grow: 1;
    }

        .module-content-tab-order-information-container .module-content-tab-order-information-box p {
            margin: 0 0 0.3em 0;
        }

.product-price-amountBtn-container {
    position: relative;
    /*display: flex;
    grid-gap: 10px;*/
}

    .product-price-amountBtn-container > button:not(:first-child), .product-price-amountBtn-container > div:not(:first-child) {
        margin: 0 0 0 10px;
    }

.staffold-items {
    min-width: 150px;
    width: -webkit-fit-content;
    width: fit-content;
    background: var(--backgroundColor1);
    -webkit-box-shadow: var(--shadow3);
    box-shadow: var(--shadow3);
    border-radius: $default-border-radius;
    position: absolute;
    z-index: 100;
    right: 0;
}

    .staffold-items ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

        .staffold-items ul li {
            text-align: right;
            line-height: var(--lineHeight1);
            font-weight: var(--fontWeight3);
            display: flex;
            gap: 10px;
            /*padding: 5px 20px;*/
        }

            .staffold-items ul li:first-child {
                margin-top: var(--marginTop4);
            }

            .staffold-items ul li:last-child {
                margin-bottom: var(--marginTop4);
            }

            .staffold-items ul li:hover {
                background: var(--backgroundColor2);
            }

            .staffold-items ul li a {
                color: $default-font-color-dark;
                cursor: pointer;
                font-weight: var(--fontWeight3);
                width: 100%;
                height: 100%;
                display: block;
                padding: 5px 20px;
            }

.subtleTotalPrice {
    opacity: 0.5;
}


/* ----- 6.2.3: Modules Netwerk ----- */
.module-content-netwerk {
    overflow-x: hidden;
    flex-flow: unset;
}

.module-netwerk {
    /*width: var(--widthWithMargin1);*/
}

.module-netwerk-sidebar {
    background: var(--styleColor3);
    padding: 10px;
    -webkit-transition: var(--transition1);
    -o-transition: var(--transition1);
    transition: var(--transition1);
    border-bottom-left-radius: var(--roundCornerBottomLeft1);
}

    .module-netwerk-sidebar > ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

        .module-netwerk-sidebar > ul > li {
            height: var(--inputHeight1);
        }

            .module-netwerk-sidebar > ul > li:first-child {
                /*margin-top: 0;*/
            }

.module-netwerk-sidebar-text {
    display: none;
    color: $default-font-color-dark;
    margin-left: var(--marginLeft4);
    font-weight: var(--fontWeight3);
    position: relative;
}

.module-netwerk-icon {
    color: $default-font-color-dark;
    font-size: 18px;
    width: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
}

.tab-content {
    width: 100%;
    padding: var(--padding2);
    height: 100%;
    box-shadow: none;
    background: none;

    h3 {
        margin-bottom: 0.3em;
    }
}

    .tab-content .tab-content {
        padding: 15px 0;
        height: unset;
        
        &:first-child {
            padding-top: 0;
        }
    }

    .tab-content > .tab-pane {
        height: 100%;
    }

        .tab-content > .tab-pane > p {
            color: $default-font-color-dark;
            font-size: unset;
            margin: 0 -15px;
            padding: 5px 15px;
            width: calc(100% + 30px);
            display: flex;
            
            &:nth-of-type(2n+1) {
                background: var(--backgroundColor2);
            }
            
            & > label {
                margin: 0;
            }
            
            & > label, & > span {
                width: 100%;
            }
        }

    .tab-content > h5 {
        text-align: left;
    }

    .tab-content .AddressPartial {
        width: 100%;
    }

.tab-pane-icon {
    width: 20px;
}

.tab-content .tab-content-header {
    display: flex;
    flex-flow: row wrap;
}

    .tab-content .tab-content-header * {
        width: 100%;
        text-align: center;
    }

/* Foto's */
.carousel, .carousel-inner, .carousel-indicators, .carousel-item {
    height: 100%;
}

.carousel {
    height: 300px;
    margin-bottom: var(--marginBottom3);
}

.carousel-inner {
    overflow: visible;
}

.carousel-nav {
    color: $default-font-color-dark;
    font-size: 30px;
}

.carousel-control-next, .carousel-control-prev {
    opacity: 1;
}

.carousel-inner > .carousel-item {
    border: none;
    background: none;
}

module-content-nav-btn {
    /*height: 50px;*/
}

.carousel-control-delete {
    margin: 0;
    position: absolute;
    bottom: -45px;
    right: 0;
}

.carousel-control-delete-btn {
    width: -webkit-fit-content;
    width: fit-content;
    width: -moz-fit-content;
}

.carousel-file-upload {
    margin-top: 25px;
}

.carousel-inner > .carousel-item > img {
    max-width: 100%;
    position: relative;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.carousel-control-next, .carousel-control-prev {
    z-index: unset;
}

/* Google Maps */

.googleMapsHide {
    display: none;
}

.module-content-location-btn {
    background: var(--buttonColorGreen);
    padding: 10px 15px;
    color: var(--textColorLight);
    border-radius: var(--roundCorner2);
    border: none;
    -webkit-box-shadow: 0 5px 7px 0 rgba(21,195,154,0.3);
    box-shadow: 0 5px 7px 0 rgba(21,195,154,0.3);
}

.GoogleMap {
    width: 100%;
    height: 400px;
    margin-bottom: var(--marginBottom3);
}

.content-btn-icon {
    margin-right: var(--iconMargin);
}

div.leaflet-pane, div.leaflet-control, div.leaflet-bottom, div.leaflet-top {
    /*z-index: unset;*/
}

#locationButton {
    margin: 0;
}

/* Steering */

.module-netwerk-title {
    width: 100%;
    text-align: center;
    font-family: $default-font-family-headline;
    font-size: var(--titleFontSize);
    font-weight: var(--fontWeight4);
    margin: 0 0 15px 0;
}
/* Waarden */
.module-netwerk-waarden-data-title {
    min-width: -webkit-fit-content;
    min-width: fit-content;
    min-width: -moz-fit-content;
    width: 50%;
    margin: 0 0 7px 0;
    font-weight: var(--fontWeight3);
}

.module-netwerk-waarden-data {
    margin: 0 0 7px 0;
}

/* Chargingpoint */

.tab-content-grid-box-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    gap: 20px;
}

.tab-content-grid-box-half {
}

    .tab-content-grid-box-half .icon-indicator {
        font-size: 50px;
        width: 100%;
        text-align: center;
    }

    .tab-content-grid-box-half .icon-indicator-text {
        width: 100%;
        text-align: center;
    }

.tab-content-chargingpoint-container {
    width: 100%;
    display: flex;
    column-gap: 20px;
}

.tab-content-chargingpoint-infotext {
    width: 100%;
    margin-bottom: 25px;
}

    .tab-content-chargingpoint-infotext .tab-content-chargingpoint-infotext-title {
        margin: 0 0 10px 0;
        border-bottom: var(--borderBottom1);
        display: flex;
        justify-content: space-between;
    }

        .tab-content-chargingpoint-infotext .tab-content-chargingpoint-infotext-title .module-options {
            margin: 0 6px 0 0;
        }

.tab-content-chargingpoint-container .tab-content-chargingpoint-indicator {
    width: fit-content;
    min-width: 100px;
    max-width: 120px;
    font-size: 50px;
    text-align: center;
    padding-right: 10px;
    border-right: var(--borderBottom1);
}

    .tab-content-chargingpoint-container .tab-content-chargingpoint-indicator .icon-indicator {
        text-align: center;
        transform: translate(4px, 0);
        color: #F5F5F5;
    }

        .tab-content-chargingpoint-container .tab-content-chargingpoint-indicator .icon-indicator[chargingpoint-status="Inactive"] {
            color: #F5F5F5;
        }

        .tab-content-chargingpoint-container .tab-content-chargingpoint-indicator .icon-indicator[chargingpoint-status="Charging"] {
            color: #15C39A;
        }

        .tab-content-chargingpoint-container .tab-content-chargingpoint-indicator .icon-indicator[chargingpoint-status="Active"] {
            color: #15C39A;
        }

        .tab-content-chargingpoint-container .tab-content-chargingpoint-indicator .icon-indicator[chargingpoint-status="Problem"] {
            color: #CC3B33;
        }

        .tab-content-chargingpoint-container .tab-content-chargingpoint-indicator .icon-indicator[chargingpoint-status="Done"] {
            color: #00722D;
        }

    .tab-content-chargingpoint-container .tab-content-chargingpoint-indicator .icon-indicator-text {
        font-weight: var(--fontWeight3);
        text-align: center;
    }


/* ----- 6.2.4: Modules Controllers ----- */
.module-tab-content-title {
    font-weight: var(--fontWeight3);
    width: 100%;
    //margin-bottom: 2px;
    color: $default-font-color-dark;
    padding: 0;
}

// In commentaar omdat het de default styling overschrijft en niet nodig lijkt. opgemerkt in de modal op de marketplace om van aansluitpunt te wissele'.
//.module-tab-content-body-group > .module-tab-content-title {
//    margin: 0 0 0 10px;
//    width: unset;
//    padding: 0 !important;
//}

.module-tab-content-body {
    font-weight: var(--fontWeight2);
    width: 100%;
    float: left !important;
    margin-bottom: var(--marginBottom4);
    border: none;
    color: $default-font-color-dark;
    padding: 0;
}

.alert-info {
    margin-top: var(--marginTop1);
}

.content-flex-100 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}

.module-tab-content-spinner {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.spinner-border {
    height: 15px;
    width: 15px;
}

#settings-target {
    grid-column: 1 / span 3;
}

.module-tab-content-mail-btns {
    display: flex;
    column-gap: 15px;
    row-gap: 15px;
    width: 100%;
    flex-flow: row wrap;
}

    .module-tab-content-mail-btns a, .module-tab-content-mail-btns button {
        margin: 0;
    }






/* ----- 6.2.6: Advisory Aansluitpunten ----- */
.servicepoint-list {
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    width: 100%;
}

    .servicepoint-list .servicepoint-list-item {
        border: none;
        border-radius: 10px;
        margin: 0;
        padding: 0 15px;
        width: 100%;
        display: none;
    }

        /*.servicepoint-list .servicepoint-list-item:nth-child(2n+1) {
            background: var(--backgroundColor2);
        }*/

        .servicepoint-list .servicepoint-list-item.every-second {
            background: var(--backgroundColor2);
        }



    .servicepoint-list .sp {
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

        .servicepoint-list .sp h5 {
            margin: 0;
            text-align: left;
            width: 100%;
            font-family: $default-font-family-text;
            font-size: 13px;
        }

    .servicepoint-list .sp-name {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        cursor: pointer;
        padding: 10px 15px;
    }

    .servicepoint-list .sp-active {
        border-bottom: var(--borderBottom1);
    }

    .servicepoint-list .assets, .servicepoint-list .asset, .servicepoint-list .asset-header, .servicepoint-list .asset-detail {
        border: none;
        margin: 0;
        padding: 0;
    }

    .servicepoint-list .asset {
        display: flex;
        width: 100%;
        padding: 20px 0;
        border-bottom: var(--borderBottom1);
    }

        .servicepoint-list .asset:last-child {
            border-bottom: none;
        }

    .servicepoint-list .asset-header {
        width: 200px;
        display: inline-flex;
        padding: 0 15px 0 0;
        border-right: var(--borderRight1);
        position: relative;
    }

    .servicepoint-list .asset-detail {
        width: calc(100% - 200px);
        padding: 0 0 0 15px;
    }

        .servicepoint-list .asset-detail .asset-detail-btns {
            width: 100%;
            display: inline-flex;
            justify-content: flex-end;
        }

    .servicepoint-list .asset-inline-data {
        width: 100%;
        color: $default-font-color-dark;
    }

        .servicepoint-list .asset-inline-data p, .servicepoint-list .asset-inline-data i {
            margin: 0;
            padding: 0;
        }

    .servicepoint-list .asset-inline-data-headline {
        display: inline-flex;
    }

        .servicepoint-list .asset-inline-data-headline p {
            font-weight: var(--fontWeight3);
            font-size: 13px;
        }

        .servicepoint-list .asset-inline-data-headline i {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }

    .servicepoint-list .show-servicepoint-list-item {
        display: block;
    }

.asset-header-title {
    cursor: pointer;
    width: 100%;
}

.asset-header input[type="checkbox"] {
    margin: 5px 10px 0 0;
}

.servicepoint-list .asset-detail .asset-title {
    display: none;
}

.servicepoint-list .sp-colapse {
    border: none;
    padding: 0;
    /*margin: 0 10px 0 0;*/
    background: none;
}

.servicepoint-list .sp i {
    font-size: var(--caretSize1);
    color: $default-font-color-dark;
    margin: 0 10px 0 0;
    height: 17px;
    width: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.asset-colapse-caret {
    font-size: var(--caretSize1);
    color: $default-font-color-dark;
    margin: 0;
    height: 17px;
    width: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -19px;
    top: 0;
}

.no-asset {
    width: 100%;
    text-align: center;
    padding: 10px;
}

.invalidated-banner {
    display: none;
}

.deferred-invalidated .invalidated-banner {
    position: fixed;
    background: var(--styleColor3);
    box-shadow: var(--shadow2);
    right: 0;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    padding: 15px 100px;
    width: calc(100vw - 70px);
    z-index: 20000;
    transition: 0.3s ease-in-out;
}

.show-navbar.deferred-invalidated .invalidated-banner {
    width: calc(100vw - 200px);
}

.invalidated-banner-content {
    display: inline-flex;
    width: 100%;
    align-items: center;
}

    .invalidated-banner-content h5 {
        margin: 0;
        flex-grow: 1;
    }

/* ----- 6.2.7: Advisory Steering ----- */

/*.steering {
    border: 1px solid red;
}*/

.steering-header {
    display: flex;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    padding: 5px 15px;
    align-items: center;
    border-radius: 10px 10px 0 0;
    border-bottom: var(--borderBottom1);
}

    .steering-header select {
        font-weight: var(--fontWeight2);
        font-size: var(--inputFontSize);
        width: 40%;
        float: left !important;
        border: none;
        color: $default-font-color-dark;
        padding: var(--inputPadding);
        margin: 0 10px 0 0;
        border-radius: var(--roundCorner2);
        background-color: white !important;
    }

.steering-group-title {
    font-weight: var(--fontWeight3);
    /*padding: 5px 10px;*/
}

.steering-combination {
    display: flex;
    flex-direction: row;
    /*margin-left: 10px;*/
    padding: 5px 15px;
    align-items: center;
}

    .steering-combination:first-child {
        /*margin-top: 15px;*/
    }

.steering-operand {
    font-weight: var(--fontWeight3);
}

    .steering-operand:first-of-type {
        margin-left: auto;
    }

.steering-operator {
    /*font-weight: var(--fontWeight3);*/
}

.steering-criterion {
    font-weight: var(--fontWeight3);
}

.steering-operand-value {
    font-weight: var(--fontWeight2);
}

.steering-combination-content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    margin: 0 15px 0 0;
    column-gap: 15px;
}

    .steering-combination-content > * {
        /*margin: 0 5px;*/
    }

.criteria, #operators {
    width: 100%;
}

    .criteria > *, #operators > * {
        margin-bottom: 15px;
    }

#SteeringCriterionCombinationForm .criterion {
    border-radius: 10px;
    transition: all 333ms;
    padding: 15px;
    background: var(--backgroundColor2);
    display: flex;
    column-gap: 15px;
}

    #SteeringCriterionCombinationForm .criterion input[type="radio"] {
        position: relative;
        top: 2px;
    }

    #SteeringCriterionCombinationForm .criterion .criterion-text label {
        margin: 0;
    }

    #SteeringCriterionCombinationForm .criterion p {
        margin: 0;
    }

#SteeringCriterionCombinationForm .checked {
    background: rgba(38,70,83,.2);
}

.dummyInput {
    display: none;
}

.steering-group {
    /*border: 1px solid gray*/
}

    .steering-group .subgroups {
        /*padding: 15px 0 15px 15px;*/
        padding: 0;
        border-left: var(--borderBottom1);
    }

        .steering-group .subgroups:not(.subgroups .steering-group .subgroups) {
            border-right: var(--borderBottom1);
        }

        .steering-group .subgroups .steering-group {
            padding: 15px 0 0 15px;
        }

.advisory-steering-group {
    width: 100%;
    border-top: var(--borderBottom1);
    margin: 15px 0 0 0;
    padding: 15px 0;
}

    .advisory-steering-group .module-content-tab-content, .advisory-steering-group .module-content-tab-btns {
        width: 100%;
        padding: 0;
    }

.steering-header div[onclick] {
    color: $default-font-color-dark;
    cursor: pointer;
}

.steering-group-criteria {
    /*border-top: var(--borderBottom1);*/
    border-left: var(--borderBottom1);
    border-bottom: var(--borderBottom1);
    padding: 15px;
}

    .steering-group-criteria .steering-combination:nth-child(2n+1) {
        background: var(--backgroundColor2);
        border-radius: 10px;
    }

.steering-group .steering-group-criteria:not(.subgroups .steering-group .steering-group-criteria) {
    border-right: var(--borderBottom1);
}

#operands {
    width: 100%;
}


/* ---------- 6.3: Page Header ---------- */
.page-header {
    padding: 0 0 0 50px;
    margin-bottom: var(--marginBottom1);
}

.breadcrumb {
    background: none;
    padding: 0;
    margin: 0;
}

/* ---------- 6.4: Page Body ---------- */

.align-items-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

/*.content-groupName {
    width: 100%;
    margin-bottom: 75px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-column-gap: var(--columnGap1);
    -moz-column-gap: var(--columnGap1);
    column-gap: var(--columnGap1);
    border-bottom: 1px solid var(--backgroundColor3);
}*/

.content-groupName {
    @include gridColumns;
    width: 100%;
    height: fit-content;
    /*column-gap: 50px;*/
    gap: 50px;
    margin: 0 0 100px 0;
    /*grid-auto-rows: 0;*/
    grid-column: 1 / -1;
}

    .content-groupName:last-child {
        margin-bottom: 0;
        border-bottom: none;
    }

.content-groupName2 {
    /*width: 100%;*/
    /*margin-bottom: 50px;*/
    /*display: -webkit-box;
    display: -ms-flexbox;
    display: flex;*/
    /*-ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-column-gap: var(--columnGap1);
    -moz-column-gap: var(--columnGap1);
    column-gap: var(--columnGap1);*/
    @include gridColumns;
    border-bottom: 1px solid var(--backgroundColor3);
    padding-bottom: 50px;
    grid-column: 1 / -1;
    grid-gap: 50px;
}

.content-group-header {
    /*width: 100%;*/
    /*margin-bottom: var(--marginBottom1);*/
    /*display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;*/
    grid-column: 1 / -1;
    display: flex;
}

.content-groupName2 .content-group-header {
    display: flex;
    align-items: center;
    /*margin-bottom: 15px;*/
}

.content-masonry {
    /*-webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-column-gap: var(--columnGap1);
    -moz-column-gap: var(--columnGap1);
    column-gap: var(--columnGap1);*/
    @include gridColumns;
    width: 100%;
    gap: 50px;
    grid-column: 1 / -1;
}

/*.content-masonry:not(:first-child) {
    margin-top: 50px;
}*/

.content-masonry-reverse {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-column-gap: var(--columnGap1);
    -moz-column-gap: var(--columnGap1);
    column-gap: var(--columnGap1);
}

.content-column {
    @media only screen and (max-width: 1580px) {
        &.changeOrder1 {
            order: 1;
        }

        &.changeOrder2 {
            order: 2;
        }
    }
}

.show-navbar .content-column {
    @media only screen and (max-width: 1710px) {
        &.changeOrder1 {
            order: 1;
        }

        &.changeOrder2 {
            order: 2;
        }
    }
}

.content-column-1_-1 {
    grid-column: 1 / -1;
}

.content-column-1_2 {
    grid-column: 1 / 2;
}

.content-column-2_-1 {
    grid-column: 2 / -1;
}

.content-column-1_3 {
    grid-column: 1 / 3;
}

    .content-column-1_3 .module-container {
        grid-column: 1 / -1;
    }

.content-column-span1 {
    grid-column: span 1;
}

.content-column-span2 {
    grid-column: span 2;
}

.empty-message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: var(--padding2);
}

    .empty-message > h5 {
        font-weight: var(--fontWeight2);
        font-family: $default-font-family-text;
    }

/* ---------- 6.5: Error Page ---------- */
.cloud > svg {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.5;
    position: absolute;
    left: calc(-100vw + 100%);
    visibility: hidden;
}

    .cloud > svg > g > path {
        fill: rgba(245, 245, 245, 1);
    }


/* ---------- 6.6: Data Tables ---------- */
// moved to Components/_DataTables.scss

/* ---------- 6.7: Legend ---------- */

.module-legend {
    max-height: 100%;
    /*margin-bottom: 50px;*/
}

/*.module-legend:last-child {
    margin: 0;
}*/

.module-list {
    grid-column: span 2;
}

.content-legend {
    // Tijdelijk in commentaar voor overflow fix
    /*max-height: calc(100vh - 315px);*/
    height: fit-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    grid-column: 1 / 2;
    display: grid;
    gap: 50px;
}

    .content-legend .tab-content {
        height: 100%;
    }

.content-legend-notSticky {
    /*max-height: unset;*/
    position: relative;
    /*@include gridColumns;*/
    @include gridColumns;
    gap: 50px;
}

.content-legend.only-mobile {
    display: none
}

.content-legend-small {
    @include gridColumns(250px);
}

.legend-content-groups {
    overflow-y: auto;
    /*height: calc(100% - 53px);*/
    //margin: var(--margin5);
    max-height: calc(100vh - 469px);
}

.content-legend-items {
    /*width: calc(100% - 450px - 25px);*/
    /*left: calc(450px + 25px);
    position: relative;*/
    width: unset;
    grid-column: 2 / -1;
    height: fit-content;
}

.module-content-search {
    width: 100%;
    position: relative;
}

.searchBar-legend {
    width: 100%;
    padding: var(--inputPadding);
    border-radius: var(--roundCorner2);
    border: none;
    font-size: var(--inputFontSize);
    background-color: var(--backgroundColor2);
}

.module-content-search > button {
    position: absolute;
    right: 12px;
    top: 7px;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font-size: var(--searchBtnFontSize);
}

    .module-content-search > button > i {
        color: var(--styleColor2);
    }

.legend-content-group {
    margin: 0 0 20px 0;
}

    .legend-content-group:last-child {
        margin: 0;
    }

.legend-content-group-header {
    /*position: relative;*/
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    background: var(--backgroundColor2);
    border-radius: $default-border-radius;
    padding: var(--padding1);
}

    .legend-content-group-header > input {
        margin-left: var(--marginLeft4);
    }

    .legend-content-group-header > h3 {
        margin: var(--margin7);
        font-family: $default-font-family-headline;
        font-size: var(--titleFontSize);
        font-weight: var(--fontWeight4);
        width: 100%;
        text-align: center;
    }

    .legend-content-group-header > button, .legend-content-group-body > li > button {
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        position: relative;
        top: 1px;
    }

.legend-group-colapse {
    width: 25px;
}

.legend-content-group-body {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
}

    .legend-content-group-body > li {
        width: 100%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 20px;
        height: 20px;
        /*position: relative;*/
    }

        .legend-content-group-body > li:first-child {
            margin-top: var(--marginTop4);
        }

        .legend-content-group-body > li:last-child {
            margin-bottom: var(--marginBottom4);
        }

        .legend-content-group-body > li > h4 {
            width: 100%;
            margin: var(--margin7);
            font-size: unset;
            font-weight: var(--fontWeight2);
            white-space: nowrap;
            overflow: hidden;
            height: 14px;
        }

        .legend-content-group-body > li > button > i {
            font-size: $default-font-size;
        }

    .legend-content-group-body > .legend-content-group-body-item-tab-1 {
        padding: 0 20px 0 44px;
    }

.legend-content-group-body-item-options {
    display: none;
}

.show-legend-options {
    display: block;
}

.legend-content-group-body-item:hover .legend-content-group-body-item-options {
    display: block;
}

.content-legend:not(.edit-default-in-main-chart) .default-group.empty-group {
    display: none;
}

input[type=checkbox], input[type=radio] {
    cursor: pointer;
    -webkit-transform: scale(1,1);
    transform: scale(1,1);
}

.legend-content-group-header .legend-item-dropdown {
    /*top: 31px;*/
}

.legend-item-dropdown, .module-options-dropdown {
    display: none;
    min-width: 150px;
    width: -webkit-fit-content;
    width: fit-content;
    width: -moz-fit-content;
    background: var(--backgroundColor1);
    -webkit-box-shadow: var(--shadow3);
    box-shadow: var(--shadow3);
    border-radius: $default-border-radius;
    //position: absolute;
    /*right: 0;*/
    /*top: 20px;*/
    //z-index: 100;
    padding: 10px 0;
    
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            text-align: right;
            line-height: var(--lineHeight1);
            font-weight: var(--fontWeight3);
            color: $default-font-color-dark;
            cursor: pointer;
            width: 100%;
            height: 100%;
            display: block;
            padding: 5px 20px;
            
            &:hover {
                background: var(--backgroundColor2);
            }
            
            //&:first-child {
            //    margin-top: var(--marginTop4);
            //}
            //
            //&:last-child {
            //    margin-bottom: var(--marginBottom4);
            //}
            
            a {
                color: $default-font-color-dark;
                cursor: pointer;
                font-weight: var(--fontWeight3);
                width: 100%;
                height: 100%;
                display: block;
            }
        }
    }
}

.mobile-legend-btn {
    display: none;
}

.legend-content-group-body > li {
    height: 23px;
}

.module-content-options-list {
    width: 100%;
    padding: 0;
}

    .module-content-options-list > h5 {
        padding: 0;
    }

    .module-content-options-list > ul {
        width: 100%;
        list-style: none;
        padding: 0;
    }

        .module-content-options-list > ul > li {
            width: 100%;
            padding: 5px 0;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-column-gap: 10px;
            -moz-column-gap: 10px;
            column-gap: 10px;
        }

            .module-content-options-list > ul > li > label {
                margin: 0;
                position: relative;
                top: -2px;
            }

.module-content-half > .module-content-options-list {
    /*width: calc(50% - 10px);*/
    display: inline-block;
    margin-bottom: 15px;
}


/* ---------- 6.8: iframes ---------- */
.module-content-iframe {
    width: 100%;
    border-radius: 10px;
    height: 50vh;
}

.iframe-fullpage {
    width: 100%;
    height: calc(100vh - #{$default-body-padding-bottom} - #{$default-menu-padding * 2} - 10px);
    overflow: hidden;
    user-select: none;
}

.iframe-docScan {
    width: 100%;
    height: 80vh;
    overflow: hidden;
    user-select: none;
}



/* ---------- 6.9: Comparison ---------- */
.content-comparison {
    @include gridColumns;
    grid-column: 1 / -1;
    grid-gap: 50px;
}

    .content-comparison .module-container {
        /*width: calc(30% - (50px / 2));
        flex-grow: 1;
        min-width: 450px;*/
        grid-column: span 2;
    }

.module-content-comparison-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;

    a {
        color: $default-font-color-dark;
    }
    
    li {
        //margin: 0 0 25px 0;
        margin: 0;
        
        &:last-child {
            margin: 0;
        }
        
        &.sim {
            border: $default-border;
            border-radius: $default-border-radius;
            padding: 10px;
            
            .sim-container {
                display: flex;
                flex-flow: column wrap;
                gap: 20px;
                height: 100%;
            }
            
            .sim-buttons {
                display: flex;
                gap: 15px;
                justify-content: flex-end;
                margin-top: auto;
                
                & > * {
                    margin: 0;
                }
            }
        }
        
        .module-content-comparison-listItem-subheading {
            padding: 0px 15px 5px 15px;
        }

        .module-content-comparison-listItem-title {
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            /*column-gap: 10px;*/
            padding: 5px 15px;
            width: 100%;
            margin: 0;
            
            &.selectedSim {
                background: var(--styleColor3);
                border-radius: 10px;

                label {
                    //color: var(--textColorLight);
                }
            }
            
            label, 
            .module-content-comparison-listItem-labels label {
                /*margin: 0 0 0 10px;*/
                margin: 0;
                font-family: $default-font-family-headline;
                font-size: var(--legendHeadFontSize);
                font-weight: var(--fontWeight4);
                width: 100%;
                position: relative;
                top: 1px;
                color: $default-font-color-dark;
                white-space: nowrap;
            }

            .module-content-comparison-listItem-labels {
                display: inline-flex;
                align-items: center;
                width: 100%;
            }
        }

        h5.module-content-comparison-listItem-title {
            font-family: $default-font-family-headline;
            font-size: var(--legendHeadFontSize);
            font-weight: var(--fontWeight4);
        }
    }
}

.module-content-comparison-listItem {
    padding: 0 15px;
}


.module-content-comparison-listItem-title-description {
    width: 100%;
    margin: 0 0 15px 0;
    padding: 0 15px;
}

.module-content-comparison-listItem-btns {
    display: inline-flex;
    margin: 0 0 0 10px;
}

.module-chart {
    grid-column: 1 / -1;
}



/* --------------- 7: Modals --------------- */
/* ---------- 7.1: Modals General ---------- */
/*.ServicepointAssetConfiguration, #ServicepointAssetGroupConfigurationModal, #ServicepointAssetNewModal {
    position: absolute;
    width: 80%;
    max-width: 1000px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: -webkit-fit-content;
    height: fit-content;
    height: -moz-fit-content;
    max-height: 80%;
}*/

/*.ServicepointAssetConfiguration > div.module-header {
        border-bottom: none;
    }*/

.module-content-tabs {
    padding: var(--padding2);
    background: var(--styleColor3);
    border-radius: 10px;
}

    .module-content-tabs > ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: var(--padding5);
        margin: 0;
        background: none;
    }

        .module-content-tabs > ul > li {
            list-style: none;
            padding: var(--padding5);
            border-right: var(--borderRight1);
        }

            .module-content-tabs > ul > li:last-child {
                border-right: none;
            }

            .module-content-tabs > ul > li > a {
                color: $default-font-color-dark;
                font-weight: var(--fontWeight3);
                padding: 0;
                font-size: unset;
                min-width: unset;
            }

/*button.close {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
}*/

.form-inBetween {
    margin-top: 50px;
}

.module-content-tab-content {
    padding: var(--padding2);
    width: 100%;
    flex-flow: row wrap;
    gap: 50px 30px;
}

.modal-body .module-content-tab-content, .modal-body .module-content-tab-btns {
    padding-left: 0;
    padding-right: 0;
}

.module-content-tab-content > .tab-pane > h4, .tab-content > h4 {
    width: 100%;
    text-align: center;
    font-weight: var(--fontWeight3);
    margin-bottom: var(--marginBottom3);
}

.module-content-tab-content > .tab-pane > .form-group, .module-content-tab-content > .form-group, .modal-body > form > .form-group, .tab-content > .form-group {
    width: 100%;
    margin-bottom: var(--marginBottom3);
}

.setup-content .form-group .module-tab-content-title {
    /*padding: 0;*/
}

.form-group .module-tab-content-title {
    padding: var(--padding5);
    //margin-bottom: 0.5em;
}

.form-group .module-tab-content-note {
    padding: var(--padding5);
}

.form-group .content-btn-switch-container {
    padding: var(--padding5);
}

.form-content-half .module-content-half, .form-content-half .form-group {
    width: calc(50% - 10px);
    margin-bottom: 20px;
}

.module-content > form > div:not(:first-child) {
    /*padding-top: 0;*/
}

.module-content > form > div:not(:last-child) {
    /*padding-bottom: 0;*/
}

.formInputDescription {
    padding: var(--padding5);
    width: 100%;
    font-style: italic;
}

.module-tab-content-body-group {
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    position: relative;
    gap: 5px;
    
    &.body-group-nextEachother {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    
    &.multiple-inputs {
        padding: 15px;
        border-radius: 10px;
        border: 5px solid #F5F5F5;
    }
    
    .input-wrapper {
        width: 100%;
        display: flex;
        flex-flow: column wrap;
        gap: 5px;
        
        label {
            width: 100%;
        }
    }
    
    input, textarea, .inline-input, div.form-control, .multipleInputs-row-input {
        &, &.multipleInputs-row-input input {
            font-weight: var(--fontWeight2);
            font-size: var(--inputFontSize);
            width: 100%;
            float: left !important;
            border: none;
            color: $default-font-color-dark;
            padding: var(--padding4);
            margin: 0;
            background: var(--backgroundColor2);
            
            &:not(textarea) {
                border-radius: var(--roundCorner2);
            }
            
            &[type="textareaAutoSize"] {
                resize: none;
            }

            @include size(tablet) {
                //font-size: 16px !important;
            }
        }
    }

    input[type="hidden"] & {
        margin: 0;
    }

    input[type="checkbox"] {
        width: -webkit-fit-content;
        width: fit-content;
        width: -moz-fit-content;
        margin-left: var(--marginLeft3);
        padding: 0;
    }
    
    input[readonly] {
         background: var(--backgroundColorNotAllowed);
         cursor: not-allowed;
     }

    select {
        font-weight: var(--fontWeight2);
        font-size: var(--inputFontSize);
        width: 100%;
        float: left !important;
        border: none;
        color: $default-font-color-dark;
        padding: var(--inputPadding);
        margin: 0;
        border-radius: var(--roundCorner2);
        background: var(--backgroundColor2);
    }

    button, .content-btn-row-right {
        &, button {
            //margin: var(--margin5);
            float: right;
        }
    }

    .module-tab-content-checkbox {
        width: unset;
        margin: var(--margin6);
    }

    .module-tab-content-body-group-btns {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .text-danger {
        width: 100%;
        padding: var(--padding5);
        color: var(--textColorRed);
    }
}

textarea {
    font-weight: var(--fontWeight2);
    width: 100%;
    float: left !important;
    border: none;
    color: $default-font-color-dark;
    padding: var(--padding6);
    margin: 0;
    border-radius: $default-border-radius;
    background: var(--backgroundColor2);
}

    input[readonly], select[readonly], .module-tab-content-body-group input[readonly], .form-control:disabled, .form-control[readonly] {
        cursor: not-allowed;
        -moz-appearance: textfield;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: var(--backgroundColorNotAllowed) !important;
    }
    
.selectList {    
    &, .form-control {
        position: unset !important;
    }
}

.module-tab-content-body-group-withLeftColumn {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));

    .tab-content-body-group:nth-child(2) {
        grid-column: 2 / -1;
    }
}

.content-tab {
    width: 100%;
    
    &.hide {
        display: none;
    }
}

.tab-content.hide {
    display: none;
}

.form-control.openModal {
    cursor: pointer;
}

.form-group > .module-form-label {
    font-weight: var(--fontWeight3);
}

.form-group-groupContainer {
    width: 100%;
    padding: 20px 0 0 0;
    border-top: 1px solid #DDD;
}

    /*.form-group-groupContainer:last-of-type {
        margin: 0 0 20px 0;
        padding: 0 0 20px 0;
    }*/

    .form-group-groupContainer h5 {
        width: 100%;
        text-align: center;
        margin: 0 0 20px 0;
    }

.module-content-tab-btns {
    &, & > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        position: relative;
        //padding: var(--padding2);
        width: 100%;
        align-items: center;
        gap: 10px;
        flex-flow: row wrap;
    
        &.center {
            justify-content: center;
        }
        
        &.top {
            padding-top: var(--padding2);
        }
        
        &.right {
            padding-right: var(--padding2);
        }
        
        &.bottom {
            padding-bottom: var(--padding2);
        }
        
        &.left {
            padding-left: var(--padding2);
        }
        
        &.all {
            padding: var(--padding2);
        }
        
        &.none {
            padding: 0;
        }
    }
}

    .module-content-tab-btns .content-btn-switch {
        margin: 0;
    }

//form > .module-content-tab-btns {
//    padding: 15px;
//}

.setup-content .module-content-tab-btns {
    /*padding-left: 0;
    padding-right: 0;*/
    padding: 0;
}

.module-content-tab-btns-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    margin-bottom: var(--marginBottom3);
}

.module-content-tab-btns-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    margin-bottom: var(--marginBottom3);
}

.module-content-tab-btns-4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: var(--marginBottom3);
    flex-flow: column wrap;
    gap: 10px;

    input, button, a, p, .content-btn {
        width: 100%;
        margin: 0;
        text-align: center;
    }
}

.module-btn-after-form {
    width: -webkit-fit-content;
    width: fit-content;
    width: -moz-fit-content;
    margin-left: 20px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

    .module-btn-after-form > button:not(:first-child) {
        margin: var(--margin2);
    }

.module-tab-content-body-group-multipleInputs {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
}

.multipleInputs-row {
    display: flex;
    column-gap: 15px;
    width: 100%;
    margin: 0 0 10px 0;
}

    .multipleInputs-row > .content-btn {
        margin: 0;
        height: 29px;
        width: 40px;
    }

    .multipleInputs-row > .btnPlaceholder {
        margin: 0;
        height: 29px;
        width: 40px;
        min-width: 40px;
    }

    .multipleInputs-row > .content-btn i {
        margin: 0;
    }

/*#addStaffel {
    margin: 0;
}*/

/*#detailContainer {
    flex-flow: row wrap;
}
*/
.multipleInputs-row-input {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

    .multipleInputs-row-input input {
        height: 29px;
    }

.multipleInputs-row-input-withText {
    display: flex;
    align-items: center;
    width: 100%;
}

    .multipleInputs-row-input-withText .text-danger {
        margin: 5px 0 0 0;
    }

    .multipleInputs-row-input-withText label {
        width: fit-content;
        margin: 0 10px 0 0;
    }

.module-spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.module-spinner {
    height: 50px;
    width: 50px;
}

.module-tab-content-head {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 15px 15px 0 15px;
    flex-flow: row wrap;

    p {
        margin: 0;
        text-align: center;
        padding-bottom: 15px;
        width: 100%;

        &:last-child {
            margin: 0;
            border-bottom: var(--borderBottom1);
        }
    }
}


.wizard {
    .module-tab-content-head {
        margin-bottom: 15px;
    }
}

.modal-inner-content .module-tab-content-head {
    padding: 0;
    margin-bottom: 15px;
}

.module-tab-content-head-text {
    margin: 0;
    text-align: center;
    padding-bottom: 15px;
    border-bottom: var(--borderBottom1);
    width: 100%;
}


.module-tab-content-split-2 {
    /*width: 100%;
    display: flex;
    column-gap: 50px;
    padding: 15px;*/
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    column-gap: 50px;
    padding: 15px;
}

/*.module-tab-content-split-column {
}*/

/*.module-tab-content-split-2 .module-tab-content-split-column {
    width: calc(100% - 50px);
}*/

.module-content-tab-btns > .dropdown {
    margin-left: 10px;
}

.module-content-tab-search {
    margin: 0;
    width: 100%;
    padding: 15px 15px 0 15px;
    position: relative;
}

    .module-content-tab-search input {
        width: 100%;
        padding: var(--inputPadding);
        border-radius: var(--roundCorner2);
        border: none;
        font-size: var(--inputFontSize);
        background-color: var(--backgroundColor2);
    }

    .module-content-tab-search > button {
        position: absolute;
        right: 26px;
        top: 19px;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        font-size: var(--searchBtnFontSize);
    }

        .module-content-tab-search > button > i {
            color: var(--styleColor2);
        }

.search-input {
    position: absolute;
    right: 15px;
    top: 5px;
    border: none;
    background: none;
    padding: 0;
    margin: 0 !important;
    font-size: var(--searchBtnFontSize);
}

    .search-input i {
        color: var(--styleColor2);
    }

.module-content-searchbar {
    margin: 0;
    padding: 0;
    position: relative;
}

    .module-content-searchbar > input {
        width: 100%;
        padding: var(--inputPadding);
        border-radius: var(--roundCorner2);
        border: none;
        font-size: var(--inputFontSize);
        background-color: var(--backgroundColor2);
    }

    .module-content-searchbar > button {
        position: absolute;
        right: 11px;
        top: 4px;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        font-size: var(--searchBtnFontSize);
    }

        .module-content-searchbar > button > i {
            color: var(--styleColor2);
        }

.module-content-tabContainer {
    width: 100%;
    padding: 0;
    margin: 0;
}

.module-content-tab {
    width: 100%;
    padding: 0;
    margin: 0;
    display: none;
}


.module-content-tab-show {
    display: block;
}

#backToProductList {
    display: none;
}

    #backToProductList.show {
        display: block;
    }



select.form-control:not([size]):not([multiple]) {
    height: var(--inputHeight1);
}

.modal-body {
    padding: 15px;
    width: 100%;
}

.modal-header .close {
    padding: unset;
    margin: unset;
}

.module-header .close {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.module-info {
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.module-info-button {
    opacity: 50%;
    transition: opacity 333ms ease-out;
}

    .module-info-button:hover {
        opacity: 100%;
    }

.module-info > *:not(:last-child) {
    margin-right: 5px;
}

.modal-dialog, .modal-content {
    border-radius: $default-border-radius;
    border: none;
    position: relative;
    /*top: 50%;*/
    /*transform: translate(0, -50%);*/
}

.modal-header {
    height: var(--headerHeight1);
    text-align: center;
    margin: 0 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    padding: 0;
}

    .modal-header > h4 {
        font-family: $default-font-family-headline;
        font-size: var(--titleFontSize);
        font-weight: var(--fontWeight4);
        margin-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        max-width: 75%;
        position: relative;
        left: 50%;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }


.modal-content .modal-header {
    border-bottom: var(--borderBottom1);
}

.modal-content > .module-container {
    margin: 0;
}

.module-graph {
    width: calc(100% - 450px - 25px);
    height: -webkit-fit-content;
    height: fit-content;
    height: -moz-fit-content;
}

.module-graph-options {
    /*width: calc(450px - 25px);*/
    //max-height: calc(100vh - 315px);
    //max-height: calc(100vh - 60px);
    /*overflow-y: scroll;*/
}

    .module-graph-options .tab-content ul {
        list-style: none;
        padding: 0;
        max-height: 100%;
        height: 100%;
    }
    
.chartsContainer {
    @include size(tablet) {
        .content-legend {
            grid-row: 2;
        }
        
        .content-legend-items {
            grid-row: 1;
            //height: calc(100vh - 60px - 10px - 15px);
            gap: 25px;
            grid-template-rows: fit-content(100%);
        }
    }
}

.module-header-options {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: var(--marginBottom3);
}

    .module-header-options .searchBar {
        width: 250px;
        padding: var(--inputPadding);
        border-radius: var(--roundCorner2);
        border: none;
        font-size: var(--inputFontSize);
        background: var(--backgroundColor2);
        height: 100%;
    }

.modal {
    /*z-index: unset;*/
}

.modal-backdrop {
    z-index: unset;
}

.module-text-loose {
    margin: 0 0 5px 0;
}

.modal-footer {
    border-top: none !important;
}

.modal-backdrop.show {
    z-index: 160;
}

.twoFactorAuth {
    width: 100%;
    display: flex;
    /*flex-flow: column nowrap;*/
    column-gap: 15px;
    align-items: center;
}

    .twoFactorAuth .QRCode {
    }

    .twoFactorAuth .QRInfo {
        width: 100%;
    }

/*.module-content-tab-content-QR {
    width: 100%;
}*/

/* ---------- 7.2: Version ---------- */

.versionOverlay {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: var(--modalBackgroundColor1);
}

.versionModal {
    border: none;
    border-radius: $default-border-radius;
    width: -webkit-fit-content;
    width: fit-content;
    width: -moz-fit-content;
    background: var(--backgroundColor1);
    padding: 30px;
    -webkit-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
    position: relative;
}

    .versionModal h3 {
        font-weight: var(--fontWeight4);
        font-family: $default-font-family-headline;
        text-align: center;
    }

    .versionModal p {
        margin: 0;
        text-align: center;
    }

/* ---------- 7.3: Shortcuts ---------- */

.shortcutsOverlay {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: var(--modalBackgroundColor1);
}

.shortcutsModal {
    max-width: 80vw;
    max-height: 80vh;
}

    .shortcutsModal ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

        .shortcutsModal ul li {
            margin: 0;
            padding: 5px 0;
            display: flex;
        }

    .shortcutsModal .shortcutKeys {
        margin: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

        .shortcutsModal .shortcutKeys i {
            margin: 0 10px;
        }

    .shortcutsModal .shortcutDescription {
        margin-left: 10px;
        line-height: 1.7em;
    }



/* --------------- 8: Charts --------------- */
/* ---------- 8.1: Main Chart ---------- */

.chartItem {
    grid-column: 1 / -1;
}

.chartContainer {
    padding: 0 0 25px 0;
    position: relative;
    height: 400px;
    border-radius: $default-border-radius;
    /*overflow: hidden;*/
    margin-top: 25px;
}

.chartSelection {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 15px 15px 0 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

    .chartSelection > .datePicker {
        border-radius: var(--roundCorner2);
        padding: var(--inputPadding);
        border: none;
        font-size: var(--inputFontSize);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22caret-down%22%20class%3D%22svg-inline--fa%20fa-caret-down%20fa-w-10%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M31.3%20192h257.3c17.8%200%2026.7%2021.5%2014.1%2034.1L174.1%20354.8c-7.8%207.8-20.5%207.8-28.3%200L17.2%20226.1C4.6%20213.5%2013.5%20192%2031.3%20192z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
        background-position: right 1.1em top 54%, 0 0;
        background-size: .65em auto, 100%;
        background-repeat: no-repeat, repeat;
        background-color: var(--backgroundColor2);
        cursor: pointer;
    }

    .chartSelection > p {
        margin: 0 15px 0 0;
    }

#chartjs-tooltip {
    left: 63px;
    width: 100%;
    font-size: var(--inputFontSize);
    padding: 0 25px;
    margin-bottom: var(--marginBottom2);
    text-align: left;
}

    #chartjs-tooltip > ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

.gestureOverlay {
    width: 100%;
    height: calc(100% - 50px);
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 50px;
    border-radius: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 50px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

    .gestureOverlay > p {
        font-size: var(--titleFontSize);
        color: var(--textColorLight);
        text-align: center;
    }

#chartLegend {
    max-height: calc(100vh - 416px);
    height: 100%;
}

.chart-legend-head > h4, .module-chart-result-legend > h4 {
    text-align: center;
    margin-bottom: var(--marginBottom3);
    font-size: var(--titleFontSize);
    color: $default-font-color-dark;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--backgroundColor1);
    padding-bottom: 10px;
}

.chart-legend-maxAssets {
    margin-top: var(--marginTop3);
    text-align: center;
    font-size: var(--titleFontSize);
}

.chart-legend-groups {
    overflow-y: auto;
    max-height: calc(100vh - 506px);
    height: 100%;
}

.chart-legend-body {
    margin-bottom: var(--marginBottom3);
    background: var(--backgroundColor2);
    padding: var(--padding2);
    list-style: none;
    border-radius: $default-border-radius;
}

    .chart-legend-body:last-child {
        margin-bottom: 0;
    }

    .chart-legend-body > ul {
        list-style: none;
        padding: 0;
    }

        .chart-legend-body > ul > li {
            padding: 7px;
            padding-right: 15px;
        }

            .chart-legend-body > ul > li:last-child {
                margin-bottom: 0;
            }

            .chart-legend-body > ul > li:nth-child(odd) {
                background: var(--backgroundColor2);
            }

            .chart-legend-body > ul > li > a {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                width: 100%;
                color: $default-font-color-dark;
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }

    .chart-legend-body > a {
        width: 100%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        white-space: nowrap;
    }

        .chart-legend-body > a > i {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 30px;
        }

.chart-legend-group-name {
    /*font-size: 1.5em;*/
    text-align: center;
    color: $default-font-color-dark;
    font-family: $default-font-family-headline;
    font-weight: var(--fontWeight4);
}

    .chart-legend-group-name > a {
        text-align: center;
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

.chart-legend-item-color {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 2px;
    width: 30px;
    height: 30px;
    margin-right: var(--iconMargin);
    min-width: 30px;
    min-height: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: var(--textColorLight);
    font-weight: var(--fontWeight3);
    border-radius: $default-border-radius;
}

.chart-legend-item-value {
    white-space: nowrap;
    margin-left: auto;
}

.legend-item {
    width: 100%;
    margin: 0 0 40px 0;
}

ul > .legend-group:last-child > .legend-item {
    margin: var(--margin3);
}

.legend-group {
    display: flex;
    gap: 10px;
}

.legend-colapsable {
    width: 10px;
    background: var(--styleColor3);
    border-radius: 10px;
    cursor: pointer;
    /*margin: 0 0 40px 0;*/
}

.chart-legend-body h3 {
    width: 100%;
    font-family: $default-font-family-headline;
    font-size: var(--titleFontSize);
    font-weight: var(--fontWeight4);
    margin: var(--margin2);
}

.legend-item h4 {
    width: 100%;
    font-family: $default-font-family-text;
    font-size: var(--legendHeadFontSize);
    font-weight: var(--fontWeight3);
    margin: var(--margin2);
}

.legend-item h5 {
    font-family: $default-font-family-text;
    font-size: unset;
    font-weight: var(--fontWeight2);
    padding: 0 0 0 10px;
    margin: 0;
    white-space: nowrap;
}

.legend-item p {
    font-family: $default-font-family-text;
    font-size: $default-font-size;
    font-weight: var(--fontWeight2);
    padding: 0 0 0 10px;
    margin: 0;
    white-space: nowrap;
    text-align: right;
    width: 100%;
}

.legend-item-group a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    margin: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: unset;
}

.chart-legend-body > a.legend-group-open {
    border-bottom: var(--borderBottom1);
    width: 100%;
    padding: var(--padding3);
    margin: var(--margin3);
}

.nav-btns-mobile-charts {
    display: none;
}

.module-chart-result-legend {
    display: none;
}

.chart-legend-abbr {
    color: var(--buttonColorGreen);
}


/* ---------- 8.2: Netwerk Chart ---------- */
.netwerkChart {
    width: 100%;
    height: 100%;
}

/* ---------- 8.3: Chart Spinner ---------- */

.chart-spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.chart-spinner {
    height: 50px;
    width: 50px;
}

/* ---------- 8.4: Chart Error ---------- */

.chart-error {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.chart-error-hide {
    display: none;
}

/* --------------- 9: Map --------------- */

.mapItem {
    grid-column: 1 / -1;
}

    .mapItem .module-content {
        height: calc(100vh - 400px);
        min-height: 500px;
    }

.mapContainer {
    padding: var(--padding2);
    height: 100%;
    width: 100%;
}

.content-map {
    width: 100%;
    /*height: 800px;*/
    height: 100%;
    border-radius: $default-border-radius;
}

    .content-map .leaflet-popup-content {
        margin: 0;
        padding: var(--padding2);
        width: fit-content;
    }

        .content-map .leaflet-popup-content h5 {
            text-align: left;
            margin: 0;
            padding: 0;
        }

        .content-map .leaflet-popup-content p {
            text-align: left;
            margin: 0;
            padding: 0;
        }

            .content-map .leaflet-popup-content p.popup-title {
                font-weight: var(--fontWeight3);
                margin-top: 10px;
            }

            .content-map .leaflet-popup-content p.popup-value {
            }

    .content-map .leaflet-popup-content-wrapper {
        border-radius: 10px;
    }

    .content-map .leaflet-popup-close-button {
        padding: 10px 10px 0 0;
    }

    .content-map .content-map-legend {
        padding: var(--padding2);
        margin: 0 0 40px 40px !important;
        background: var(--backgroundColor1);
        border-radius: $default-border-radius;
        -webkit-box-shadow: var(--shadow2);
        box-shadow: var(--shadow2);
    }

        .content-map .content-map-legend:empty {
            display: none;
        }

        .content-map .content-map-legend h5 {
            text-align: center;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: var(--borderBottom1);
        }

        .content-map .content-map-legend ul, #map-legend ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

            .content-map .content-map-legend ul li, #map-legend ul li {
                display: flex;
                column-gap: 10px;
                align-items: center;
                width: 100%;
                margin-bottom: 10px;
            }

                .content-map .content-map-legend ul li:last-child, #map-legend ul li:last-child {
                    margin-bottom: 0;
                }

        .content-map .content-map-legend .legend-item-color, #map-legend .legend-item-color {
            width: 20px;
            height: 20px;
            border-radius: 10px;
        }



/* --------------- 10: Responsive --------------- */
@include size(screen-enormous) {
    /* 6.2: Modules */

    .module-netwerk {
        /*width: calc(20% - 40px);*/
    }

    .module-controller {
        /*width: calc(10% - 25px);*/
    }

    .module-overzicht {
        width: calc(10% - 25px);
    }

    .module-tabel {
        width: calc(25% - 25px);
    }
    /* 6.2.5: Modules Shop */

    .module-content-shop-product {
        width: 10%;
    }
    /* 6.11: Marketplace */

    .marketplace-productContainer-gridView .marketplace-product {
        /*width: calc((100% / 5) - 20px);*/
    }
}

@include size(screen-larger)  {
    /* 6.2: Modules */

    .module-netwerk {
        /*width: calc(25% - 38px);*/
    }

    .module-controller {
        /*width: calc(20% - 25px);*/
    }

    .module-overzicht {
        width: calc(20% - 25px);
    }

    .module-tabel {
        width: calc(33% - 25px);
    }
    /* 6.2.2: Modules General */

    .content-column-2 {
        width: calc(25% - ((50px * 3) / 4));
    }
    /* 6.2.5: Modules Shop */

    .module-content-shop-product {
        width: 16%;
    }
    /* 6.11: Marketplace */

    .marketplace-productContainer-gridView .marketplace-product {
        /*width: calc((100% / 5) - 20px);*/
    }
}

@include size(screen-large)  {
    /* 6.2: Modules */

    .module-netwerk {
        /*width: calc(33% - 27px);*/
    }

    .module-controller {
        /*width: calc(25% - 25px);*/
    }

    .module-overzicht {
        width: calc(20% - 25px);
    }

    .module-tabel {
        width: var(--widthWithMargin1);
    }

    .content-legend-items .module-netwerk {
        /*width: calc(33% - 28px);*/
    }
    /* 6.2.2: Modules General */

    .content-column-2 {
        width: calc(33% - ((50px * 2) / 3));
    }
    /* 6.2.5: Modules Shop */

    .module-content-shop-product {
        width: 20%;
    }
    /* 6.11: Marketplace */

    .marketplace-productContainer-gridView .marketplace-product {
        /*width: calc((100% / 4) - 20px);*/
    }
}

@include size(screen)  {
    /* 6.2: Modules */

    .module-netwerk {
        /*width: var(--widthWithMargin1);*/
    }

    .module-controller {
        /*width: calc(33% - 25px);*/
    }

    .module-overzicht {
        width: calc(33% - 25px);
    }

    .module-tabel {
        width: calc(100%);
    }

    .content-legend-items .module-netwerk {
        /*width: var(--widthWithMargin1);*/
    }
    /* 6.2.2: Modules General */

    .content-column-2 {
        width: calc(50% - ((50px * 1) / 2));
    }
    /* 6.2.5: Modules Shop */

    .module-content-shop-product {
        width: 33%;
    }
    /* 6.11: Marketplace */

    .marketplace-productContainer-gridView .marketplace-product {
        /*width: calc((100% / 3) - 20px);*/
    }
}

@include size(desktop-larger)  {
    /* 6.9: Comparison */

    .content-comparison {
        flex-flow: row wrap;
    }
    /* 6.11: Marketplace */

    .marketplace-productContainer-gridView .marketplace-product {
        /*width: calc((100% / 3) - 20px);*/
    }
}

@include size(desktop-large)  {
    /* 6.2: Modules */

    .module-controller {
        /*width: var(--widthWithMargin1);*/
    }

    .module-overzicht {
        width: var(--widthWithMargin1);
    }

    .module-graph {
        width: 100%;
    }

    .module-graph-options {
        width: 100%;
        height: 100%;
    }

    .chart-legend-groups {
        max-height: calc(100vh - 493px);
    }

    .content-masonry-reverse {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-column-gap: 50px;
        -moz-column-gap: 50px;
        column-gap: 50px;
    }

    .content-legend-items .module-netwerk {
        /*width: calc(100%);*/
    }
    /* 6.2.5: Modules Shop */

    .module-content-shop-product {
        width: 50%;
    }
    /* 6.11: Marketplace */

    .marketplace-productContainer-listView .marketplace-product {
        /*flex-flow: row wrap;*/
    }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-image {
            /*width: 100%;*/
        }

            .marketplace-productContainer-listView .marketplace-product .marketplace-product-image img {
                /*width: 150px;*/
                height: auto;
                max-height: 150px;
                border-radius: 10px;
                /*position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);*/
            }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo {
            width: calc(70% - (15px / 1));
            padding: 0;
        }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo {
            width: 30%;
            padding: 0;
        }

    .marketplace-productContainer-gridView .marketplace-product {
        /*width: calc((100% / 2) - 20px);*/
    }
}

@include size(desktop) {
    /* 6.2: Modules */

    .module-netwerk {
        /*width: 100%;*/
    }

    .module-overzicht {
        width: calc(67% - 25px);
    }

    .module-dataTable-location {
        margin: 0 0 20px 0;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        height: unset;
    }
    /* 6.2.6: Advisory Aansluitpunten */

    .servicepoint-list .asset {
        flex-flow: column wrap;
    }

    .servicepoint-list .asset-header {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #AAA;
        margin: 0 0 15px 0;
        padding: 0 0 10px 0;
    }

    .servicepoint-list .asset-detail {
        padding: 0;
        width: 100%;
    }

    .asset-colapse-caret {
        top: unset;
        right: 0;
        bottom: -18px;
    }

    .servicepoint-list .asset {
        padding: 10px 0;
    }
    
}

@include size(desktop-small) {
    body {
        overflow-x: hidden;
    }
}

@include size(tablet)  {
    /* 6.2: Modules */

    .module-controller {
        /*width: 100%;*/
    }

    .module-overzicht {
        width: calc(100%);
    }

    .content-legend {
        width: 100%;
        max-height: unset;
        position: relative;
        margin-bottom: 50px;
        grid-column: 1 / -1;
    }

        .content-legend .module-legend {
            grid-column: 1 / -1;
        }

    .content-legend-items {
        width: 100%;
        grid-column: 1 / -1;
    }

    .content-column-1_2 {
        grid-column: 1 / -1;
    }

    .content-column-2_-1 {
        grid-column: 1 / -1;
    }

    .content-column-1_3 {
        grid-column: 1 / -1;
    }

    .content-column-span1 {
        grid-column: 1 / -1;
    }

    .content-column-span2 {
        grid-column: 1 / -1;
    }

    .content-legend-notSticky {
        margin-bottom: 0;
    }

    #settings-target {
        grid-column: 1 / -1;
    }
    /* 6.2.2: Modules General */
    .content-column-2 {
        width: 100%;
    }
    /* 6.2.5: Modules Shop */
    /*.module-content-shop-product {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        width: 33%;
    }

    .module-content-shop .module-content-shop-product-image {
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 0 20px 0;
    }

    .module-content-shop .module-content-shop-product-info {
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

        .module-content-shop .module-content-shop-product-info h5 {
            text-align: center;
        }

        .module-content-shop .module-content-shop-product-info p {
            text-align: center;
        }

        .module-content-shop .module-content-shop-product-info ul {
            width: 100%;
            list-style: none;
            text-align: center;
            padding: 0;
        }

        .module-content-shop .module-content-shop-product-info .module-content-shop-product-info-pricing {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }*/
    /* 6.9: Comparison */

    .content-comparison {
        flex-flow: row wrap;
    }
    /* 6.11: Marketplace */

    .module-content-tab-filter .marketplace-sort p {
        display: unset;
    }

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-image {
        display: unset;
        justify-content: unset;
        align-items: unset;
    }

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo {
        width: calc(70% - (15px / 1));
        /*padding: 15px;*/
    }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo h5, .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo p {
            text-align: left;
        }

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo {
        width: 30%;
        /*padding: 15px;*/
        align-items: flex-end;
        flex-flow: column wrap;
    }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .marketplace-product-description-priceInfo-price h3 {
            text-align: right;
        }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .productRating {
            justify-content: flex-end;
        }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo p {
            text-align: right;
        }

    .marketplace-productContainer-gridView .marketplace-product {
        /*width: calc((100% / 2) - 20px);*/
    }
}

@include size(tablet-regular)  {
    .module-content-shop-product {
        width: 50%;
    }

    .twoFactorAuth {
        flex-flow: column nowrap;
        row-gap: 15px;
    }
    /* 6.11: Marketplace */

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-image {
        display: flex;
        /*justify-content: center;*/
        align-items: center;
        /*flex: 0;*/
    }

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo {
        width: 100%;
        padding: 0;
        /*flex: 1;*/
    }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo h5, .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo p {
            /*text-align: center;*/
        }

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo {
        width: 100%;
        padding: 0;
        /*align-items: center;
        flex-flow: unset;*/
        /*flex: 1;*/
    }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .marketplace-product-description-priceInfo-price h3 {
            /*text-align: left;
            margin: 0;*/
        }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .productRating {
            justify-content: flex-start;
        }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo p {
            text-align: left;
            margin: 0;
        }
}

@include size(mobile) {
    /* 2: Typography */

    .page-header h5 {
        text-align: center;
    }

    .module-content.module-block.module-content-multiple h5, .module-content.module-block.module-content-multiple p {
        text-align: center;
    }
    /* 3.4: Loading Steps */
    .drag-area {
        padding: 20px;
        text-align: center;
    }

        .drag-area span {
            width: 100%;
            margin: 10px 0;
        }

    .fileName-Container {
        margin: 10px 0 0 0;
    }

    .step-bullet {
        width: 20px;
        height: 20px;
    }

    .module-content-step {
        /*word-break: break-word;*/
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }
    /* 3.5: Daterange Picker */
    .drp-calendar.right {
        display: none !important;
    }
    /* 6.2: Modules */
    /* 6.2.2: Modules General */

    .module-container {
        /*margin-bottom: 35px;*/
    }

    .module-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .module-netwerk-sidebar {
        border-bottom-left-radius: 0;
        padding: 10px 25px;
    }

        .module-netwerk-sidebar > ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }

            .module-netwerk-sidebar > ul > li {
                height: unset;
            }

    #locationButton {
        position: relative;
        left: 50%;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }

    .hide-if-max-images {
        margin-bottom: 61px;
    }

        .hide-if-max-images > button {
            position: relative;
            left: 50%;
            -webkit-transform: translate(-50%);
            -ms-transform: translate(-50%);
            transform: translate(-50%);
        }

    .carousel-inner {
        margin-bottom: var(--marginBottom2);
        background: #333333;
    }

    .carousel-control-delete {
        position: absolute;
        bottom: -97px;
        left: 50%;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }

        .carousel-control-delete > .carousel-control-delete-btn {
            margin: 0;
        }

    .carousel-control-next, .carousel-control-prev {
        width: 50px;
        height: 50px;
        top: unset;
        bottom: unset;
        border-radius: var(--roundCorner2);
        background: #226285;
        display: none;
    }

        .carousel-control-next > i, .carousel-control-prev > i {
            color: var(--textColorLight);
            position: relative;
        }

        .carousel-control-prev > i {
            left: -2px;
        }

        .carousel-control-next > i {
            right: -2px;
        }

    .module-netwerk-waarden-data-title {
        font-weight: var(--fontWeight3);
        width: 100%;
        margin-bottom: 0.2em;
    }

    .module-netwerk-waarden-data {
        font-weight: var(--fontWeight2);
        width: 100%;
        float: left !important;
        margin-bottom: 1.5em;
    }

    .content-btn-row > div {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-column-gap: var(--columnGap2);
        -moz-column-gap: var(--columnGap2);
        column-gap: var(--columnGap2);
        row-gap: var(--rowGap1);
    }

    .content-btn-row {
        margin-bottom: 50px;
    }

    .content-btn-1, .content-btn-2, .content-btn-3, .content-btn-4 {
        margin-left: 0px;
    }

    .body-group-nextEachother .content-btn-1, .content-btn-2, .body-group-nextEachother .content-btn-3, .body-group-nextEachother .content-btn-4 {
        margin: 0 0 0 15px;
    }

    .content-group-header > h3 {
        width: 100%;
        text-align: center;
        margin: 0 0 20px 0;
    }

    .module-options-dropdown, .legend-item-dropdown {
        min-width: 50%;
    }

        .module-options-dropdown > ul > li, .legend-item-dropdown ul li {
            line-height: 35px;
        }

            .module-options-dropdown > ul > li > a, .legend-item-dropdown ul li a {
                font-size: 13px;
            }

    .nav-topBar-dropdown {
        position: fixed;
        width: 100vw;
        border-radius: 0;
        height: 100vh;
        max-height: 100vh;
        /*left: calc(-100vw + 48px);*/
        left: 0;
        top: 0;
        padding: 70px 7vw 90px 7vw;
        overflow-y: scroll;
    }

    .nav-topBar-head > a.dropdown-toggle {
        /*position: absolute;*/
        position: fixed;
        /*right: calc(5vw - 10px);*/
        right: 5vw;
        top: 10px;
        height: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .nav-topBar-dropdown > ul {
        max-height: unset;
        overflow: unset;
    }

        .nav-topBar-dropdown > ul > li {
            line-height: 30px;
        }

            .nav-topBar-dropdown > ul > li > a {
                font-size: var(--mobileInputFontSize);
            }

    .nav-topBar-submenu > li > a {
        font-size: var(--mobileInputFontSize);
    }

    .nav-topBar-dropdown-name {
        font-size: 20px;
    }

    .nav-profile > .profile-icon {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        top: unset;
    }

    .nav-profile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0 !important;
    }

    .pro-head {
        height: unset;
    }

    div.dataTables_wrapper div.dataTables_filter label {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .twoFactorAuth .QRInfo .body-group-nextEachother {
        flex-wrap: wrap;
    }

        .twoFactorAuth .QRInfo .body-group-nextEachother .module-btn-after-form {
            margin: 0;
            width: 100%;
        }

            .twoFactorAuth .QRInfo .body-group-nextEachother .module-btn-after-form .content-btn {
                margin: 20px 0 0 0;
                width: 100%;
            }

    .module-content-tab-image-list li {
        width: calc(50% - ((15px * 1) / 2));
    }

    .module-content-tab-product-variant-list {
        justify-content: center;
    }

    .module-content-tab-product-description-container h5, .module-content-item.module-content-tab-product-specifications-container h5 {
        text-align: center;
    }

    .module-content > .module-content-item:not(:last-child) {
        /*margin: 0 0 20px 0;*/
    }

    .module-content-tab-order-Address-container {
        width: 100%;
        flex-flow: row wrap;
    }

        .module-content-tab-order-Address-container .module-content-tab-order-Address-column:not(:last-child) {
            /*margin: 0 0 20px 0;*/
        }

        .module-content-tab-order-Address-container .module-content-tab-order-Address-column h5 {
            text-align: center;
        }

        .module-content-tab-order-Address-container .module-content-tab-order-Address-column p {
            text-align: center;
        }

    .module-content-tab-order-information-container .module-content-tab-order-information-box {
        padding: 15px;
        width: 100%;
        text-align: center;
    }
    /* 6.3: Page Header */

    .page-title {
        text-align: center;
    }

    .page-header {
        padding: 0;
    }

    .breadcrumb {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .content-group-header {
        -webkit-column-gap: var(--columnGap2);
        -moz-column-gap: var(--columnGap2);
        column-gap: var(--columnGap2);
        row-gap: var(--rowGap1);
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    /* 6.4: Page Body */

    .content-masonry, .content-groupName {
        /*grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));*/
        margin: 0;
    }

    .empty-message {
        padding: 0;
    }

        .empty-message > h5 {
            padding: 0;
            text-align: center;
        }
    /* 6.6: Data Tables */

    .module-dataTable-controls {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

        .module-dataTable-controls > select, .module-dataTable-controls > .saveBtnOpenerGroup > .saveBtnOpener, .module-dataTable-controls > button, .module-dataTable-controls > a {
            width: 100%;
            margin-bottom: 15px;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .module-dataTable-controls > select {
            margin-top: 15px;
        }

    .filter {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

        .filter > select, .filter > input {
            margin: 0 0 15px 0;
        }

    .saveFilterOptions {
        -webkit-transform: translate(-50%, -15px) !important;
        -ms-transform: translate(-50%, -15px) !important;
        transform: translate(-50%, -15px) !important;
    }

    .dataTables_info {
        text-align: center;
    }

    .dataTables_length .custom-select {
        margin: 0 10px 0 0;
    }

    .nav-profile > .profile-icon {
        z-index: 155;
    }

    .module-dataTable-filter {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }

    .module-dataTable-controls > h4 {
        text-align: center;
        width: 100%;
        margin: 0 0 20px 0;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .module-dataTable-filter {
        margin-bottom: 0;
    }

        .module-dataTable-filter .module-dataTable-location {
            margin: 0 0 20px 0;
        }

    .module-dataTable-controls {
        margin: 0;
    }

    .module-dataTable-controls-filter {
        margin-bottom: var(--marginBottom2);
    }

        .module-dataTable-controls-filter > div.filter {
            margin-bottom: var(--marginBottom2);
        }

    div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_paginate {
        margin-bottom: var(--marginBottom3);
    }

        div.dataTables_wrapper div.dataTables_paginate ul.pagination {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }

    table.dataTable.table-sm .sorting:before, table.dataTable.table-sm .sorting_asc:before, table.dataTable.table-sm .sorting_desc:before,
    table.dataTable.table-sm .sorting:after, table.dataTable.table-sm .sorting_asc:after, table.dataTable.table-sm .sorting_desc:after {
        display: none;
    }

    .module-header-options {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        margin-bottom: 0;
    }

        .module-header-options .searchBar {
            width: 100%;
            margin-bottom: var(--marginBottom3);
        }

    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .pagination {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        grid-row-gap: 10px;
    }

        .pagination > .paginate_button:not(.previous, .next) {
            width: 20%;
        }

        .pagination > .paginate_button:nth-last-of-type(2) {
            /*margin-right: auto;*/
        }

        .pagination > .paginate_button:not(.previous, .next):nth-child(2):nth-last-child(2) {
            padding: 0 40%;
            width: 100%;
        }

        .pagination > .paginate_button:first-child:nth-last-child(4) ~ li:not(.previous, .next) {
            width: 50%;
            padding: 0 15%;
        }

    .paginate_button.previous, .paginate_button.next {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

        .paginate_button.previous > a.page-link, .paginate_button.next > a.page-link {
            margin: 0;
        }

    div.dataTables_scrollBody table {
        overflow: hidden;
        width: fit-content !important;
    }

    .module-dataTable-btn-create {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        justify-content: center;
    }

    #irctable tbody tr td a.content-btn:not(:first-child), .table tbody tr td a.content-btn:not(:first-child) {
        margin: 0 0 0 20px;
    }

    .dataTables_scrollBody, .dataTables_scrollBody table, .dataTables_scrollBody tbody tr td {
        position: unset !important;
    }
    /*6.7: Legend*/
    //.content-legend {
    //    position: fixed;
    //    margin: 0;
    //    left: 0;
    //    bottom: 0;
    //    top: unset;
    //    max-height: unset;
    //    height: -webkit-fit-content;
    //    height: fit-content;
    //    height: -moz-fit-content;
    //    display: -webkit-box;
    //    display: -ms-flexbox;
    //    display: flex;
    //    -webkit-box-align: end;
    //    -ms-flex-align: end;
    //    align-items: flex-end;
    //    z-index: 1150;
    //
    //    &.content-legend-notSticky {
    //        z-index: unset;
    //    }
    //}

        .content-legend.only-mobile {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

    .module-legend, .module-graph-options {
        height: 100%;
        width: 100%;
        max-height: unset;
        //height: 0;
        margin: 0;
        margin-bottom: 0 !important;
        /*display: none;*/
        /*bottom: -80vh;*/
        -webkit-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        overflow: hidden;
    }

        .module-legend > .module-content, .module-graph-options > .module-content {
            /*height: calc(100% - 151px);*/
        }

            .module-legend > .module-content > .tab-content, .module-graph-options > .module-content > .tab-content {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-flow: column nowrap;
                flex-flow: column nowrap;
                //height: calc(100% - 65px);
            }

                .module-legend > .module-content > .tab-content > .legend-content-groups, .module-graph-options > .module-content > .tab-content > .legend-content-groups, .module-legend > .module-content > .tab-content > .settings-content-groups {
                    height: unset;
                    max-height: unset;
                }

    .tab-content > .legend-buttons, h4.chart-legend-maxAssets {
        margin-bottom: 35px;
        -webkit-column-gap: 20px;
        -moz-column-gap: 20px;
        column-gap: 20px;
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .tab-content > .legend-buttons {
        position: absolute;
    }

    .mobile-legend-btn {
        position: fixed;
        bottom: 25px;
        right: 25px;
        width: 40px;
        height: 40px;
        border: none;
        padding: 0;
        background: var(--buttonColorGreen);
        border-radius: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

        .mobile-legend-btn > i {
            margin: 0;
            color: var(--textColorLight);
            font-size: 15px;
        }

    .legend-content-group-body > li {
        height: 27px;
    }

    .legend-content-group-body-item-options {
        display: block;
    }

    #chartLegend {
        max-height: unset;
        height: 100%;
    }

        #chartLegend > ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
            height: 100%;
        }

            #chartLegend > ul > .chart-legend-groups {
                max-height: unset;
                height: 100%;
                /*overflow: scroll;*/
            }

    .content-legend-notSticky {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

    .module-legend-insideLayout {
        flex-flow: row wrap;
        position: unset;
        /*margin-bottom: 50px;*/
    }

        .module-legend-insideLayout .module-legend, .module-legend-insideLayout .module-graph-options {
            flex-flow: row wrap;
            height: 100%;
            /*margin-bottom: 25px !important;*/
        }

    .content-legend-items-insideLayout .module-legend {
        height: unset;
        /*margin-bottom: 25px !important;*/
    }

    .content-legend-items-insideLayout .content-group-header {
        margin: 50px 0 20px 0;
    }

        .content-legend-items-insideLayout .content-group-header h3 {
            margin: 0;
        }

    .content-legend-items-insideLayout .content-groupName2 {
        margin: 0 0 20px 0;
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
    /* 6.9: Comparison */

    .module-content-comparison-listItem-title {
        flex-wrap: wrap;
    }

    .module-content-comparison-listItem-title-description {
        text-align: left;
    }

    .module-content-comparison-listItem-btns {
        display: inline-flex;
        margin: 15px 0 0 0;
        column-gap: 10px;
        width: 100%;
        justify-content: flex-end;
    }

    .content-comparison {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

        .content-comparison .module-container {
            width: 100%;
            flex-grow: unset;
            min-width: unset;
            grid-column: 1 / -1;
        }
    
    /* 7.1: Modals General */
    .module-content-tab-btns {
        column-gap: 20px;
        row-gap: 15px;
        //justify-content: center;
        flex-wrap: wrap;
    }

    .module-content-searchbar {
        margin-bottom: 15px;
    }

    .multipleInputs-row {
        flex-flow: row wrap;
        border-bottom: var(--borderBottom1);
        padding: 5px 0 15px 0;
        row-gap: 5px;
        justify-content: flex-end;
    }

    .module-tab-content-body-group .module-tab-content-body-group-btns {
        justify-content: center;
    }
    /* 8: Charts */
    #chartjs-tooltip {
        left: 49px;
    }

    .gestureOverlay {
        display: none !important;
    }

        .gestureOverlay > p {
            display: none !important;
        }

    .chartSelection {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
    }

        .chartSelection > p {
            margin: 0 0 10px 0;
            display: none;
        }

    .nav-btns-mobile-charts {
        display: block;
    }

        .nav-btns-mobile-charts > .dropdown-menu.show {
            width: calc(100vw - 25px - 25px - 40px - 20px);
            padding: 10px 0;
        }

        .nav-btns-mobile-charts > .dropdown-menu > button {
            width: 100%;
            border: none;
            background: none;
            font-size: var(--inputFontSize);
            padding: 5px 15px;
        }

    .nav-btns-mobile-charts-select {
        width: calc(100vw - 25px - 25px - 40px - 20px);
        height: 40px;
        position: fixed;
        bottom: 25px;
        left: 25px;
        border-radius: var(--roundCorner2);
        padding: var(--inputPadding);
        border: none;
        -webkit-box-shadow: var(--shadow2);
        box-shadow: var(--shadow2);
        font-size: var(--inputFontSize);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22caret-down%22%20class%3D%22svg-inline--fa%20fa-caret-down%20fa-w-10%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M31.3%20192h257.3c17.8%200%2026.7%2021.5%2014.1%2034.1L174.1%20354.8c-7.8%207.8-20.5%207.8-28.3%200L17.2%20226.1C4.6%20213.5%2013.5%20192%2031.3%20192z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
        background-position: right 1.1em top 54%, 0 0;
        background-size: .65em auto, 100%;
        background-repeat: no-repeat, repeat;*/
        background-color: var(--backgroundColor1);
        cursor: pointer;
    }

        .nav-btns-mobile-charts-select::after {
            position: absolute;
            right: 15px;
            top: 50%;
            left: unset;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
        }

        .nav-btns-mobile-charts-select[aria-expanded="true"]::after {
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
        }

    //.content-legend-items > .chartItem {
    //    display: none;
    //}

    .chartContainer {
        height: 35vh;
        margin-top: 20px;
    }

    //.chartItem > .module-header {
    //    display: none;
    //}

    .module-chart-result-legend {
        display: block;
        padding: 0 7vw 15px 7vw;
        height: calc(100vh - 35vh - 150px);
        overflow-y: auto;
    }

        .module-chart-result-legend > ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

            .module-chart-result-legend > ul > li {
                padding: 0;
                margin: 0 0 20px 0;
            }

                .module-chart-result-legend > ul > li > h4 {
                    padding: 0;
                    margin: 0 0 5px 0;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: start;
                    -ms-flex-pack: start;
                    justify-content: flex-start;
                    width: 100%;
                    font-size: 15px;
                }

                    .module-chart-result-legend > ul > li > h4 > span {
                        display: -webkit-inline-box;
                        display: -ms-inline-flexbox;
                        display: inline-flex;
                        min-width: 20px;
                        min-height: 20px;
                        width: 20px;
                        height: 20px;
                        margin-right: var(--iconMargin);
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        color: var(--textColorLight);
                        font-weight: var(--fontWeight3);
                        border-radius: 5px;
                        font-size: $default-font-size;
                        background: green;
                    }

                .module-chart-result-legend > ul > li > p {
                    padding: 0;
                    margin: 0;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    width: 100%;
                }

        .module-chart-result-legend h5 {
            text-align: center;
            width: 100%;
        }

    //.chartItem {
    //    position: absolute;
    //    width: 100vw;
    //    height: calc(100vh - 130px);
    //    left: -7vw;
    //    top: -50px;
    //    border-radius: 0;
    //}

    .tab-content > .legend-buttons, h4.chart-legend-maxAssets {
        font-size: 13px;
        margin-bottom: 37px;
        margin-top: 28px;
        text-align: left;
    }

    .form-group > .content-btn-switch-container {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
    }
    /*.form-group > .module-tab-content-title {
        text-align: center;
    }*/
}

@include size(mobile) {

    /* 6.2: Modules */
    .module-container {
        /*margin-bottom: 25px;*/
    }

    /* 6.2.5: Modules Shop */

    .module-content-shop-product {
        width: 100%;
    }


    /*6.7: Legend*/
    .module-legend {
        margin: 0;
    }

    /*.module-header {
        height: var(--headerHeightMobile1);
    }*/

    /* 6.11: Marketplace */

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo {
        flex-flow: row wrap;
        align-items: flex-start;
    }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .marketplace-product-description-priceInfo-price {
            /*width: 70%;*/
            order: 1;
        }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .addToCartBtn {
            /*width: 30%;*/
            order: 2;
        }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .productRating {
            width: 70%;
            order: 3;
        }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo p {
            width: 30%;
            order: 4;
        }
}

/* --------------- 12: Partial Supported Code --------------- */

@supports (grid-template-rows: masonry) {
    .content-groupName, .content-masonry {
        grid-template-rows: masonry;
    }
}

/* --------------- 13: Fieldset --------------- */

fieldset {
    //all: unset;
    display: unset;
    margin-inline-start: unset;
    margin-inline-end: unset;
    padding-block-start: unset;
    padding-inline-start: unset;
    padding-inline-end: unset;
    padding-block-end: unset;
    min-inline-size: unset;
    border-width: unset;
    border-style: unset;
    border-color: unset;
    border-image: unset;
    
    //border-top: var(--borderBottom1);
    //border-bottom: var(--borderBottom1);
    border-radius: 0;
    padding: calc(25px - var(--marginBottom3)) 0;
    margin: 15px 0;
    width: 100%;
    min-width: 0;
    //display: flex;
    //flex-flow: column nowrap;
    //gap: 10px;

    legend {
        //all: unset;
        display: table;
        padding-inline-start: unset;
        padding-inline-end: unset;
        border-width: unset;
        border-style: unset;
        border-color: unset;
        border-image: unset;
        float: left;
        border-bottom: var(--borderBottom1);

        + * {
            clear: both;
        }
        
        width: 100%;
        margin: 0 0 20px 0;
        padding: var(--padding5);
        padding-bottom: 10px;
    }
}

body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
}

/*@media (pointer: coarse) {
    input[type=checkbox] {
        width: 1.5rem;
        height: 1.5rem;
    }
}*/

.comment {
    font-style: italic;
    border-left: 2px solid #333;
    background: #f5f5f5;
    padding: 5px 15px 5px 13px;
    border-radius: 0 10px 10px 0;
}

/* Translate */

.admin-translation-mode .translated:before {
    content: '\f1ab';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: relative;
    margin-right: 5px;
    left: 0;
    font-size: 11px;
    color: rgba(0,0,0,128);
}

.translation-missing:after {
    content: '\f12a';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: relative;
    margin-left: 5px;
    left: 0;
    font-size: 11px;
    color: red;
}

.tooltip-inner {
    max-width: 500px;
}

.MuiDrawer-paper {
    &:focus-visible {
        box-shadow: none !important;
    }
}

.PlattixForm {
    display: flex;
    flex-flow: column nowrap;
}

.required {
    color: red;
}