﻿/* ---------- 1.1: Typography ---------- */
/* ----- 1.1.1: Font-family ----- */

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

$default-font-family-headline: 'Assistant', 'Heebo', 'Source Sans Pro', sans-serif;
$default-font-family-text: 'Open Sans', sans-serif;

/* ----- 1.1.2: Font-size ----- */

$default-font-size: var(--regularFontSize);
$default-font-size-h1: 2.5em;
$default-font-size-h2: 1.5em;
$default-font-size-h3: 1.5em;
$default-font-size-h4: 1em;
$default-font-size-h5: var(--titleFontSize);
$default-font-size-h6: 1em;
$default-font-size-text: 1em;

/* ----- 1.1.3: Font-weight ----- */

$default-font-weight: 500;
$default-font-weight-text: 500;
$default-font-weight-h1: 700;
$default-font-weight-h2: 700;
$default-font-weight-h3: 700;
$default-font-weight-h4: 600;
$default-font-weight-h5: 700;
$default-font-weight-h6: 500;

/* ----- 1.1.4: line-height----- */

$default-font-lineHeight: 1.3em;
$default-menu-lineHeight: 1.7em;

/* ----- 1.1.5: font-margin----- */

$default-font-margin: 0 0 0.1em 0;

/* ----- 1.1.6: color----- */

/*$default-style-color-1: #15C39A;
$default-style-color-2: #264653;
$default-style-color-3: #FFBE0B;
$default-style-color-4: #333333;
$default-style-color-5: #f5f5f5;*/

$default-style-color-1: var(--styleColor1);
$default-style-color-2: var(--styleColor2);
$default-style-color-3: var(--styleColor3);
$default-style-color-4: var(--styleColor4);
$default-style-color-5: var(--styleColor5);

$default-style-color-red: #CC3B33;

$default-style-color-detail: $default-style-color-3;

$default-font-color-dark: $default-style-color-4;
$default-font-color-light: $default-style-color-5;

$default-background-color: var(--backgroundColor1);
$default-a-color: $default-style-color-1;
$default-a-color-hover: $default-style-color-2;




$default-margin: 15px;
$default-padding: 15px;
$default-menu-padding: 10px;

$default-border-line: 1px solid rgba(102, 102, 102, 0.25);
$default-border: 5px solid #F5F5F5;

$default-border-radius: 10px;
$default-module-border-radius-top-left: $default-border-radius;
$default-module-border-radius-top-right: $default-border-radius;
$default-module-border-radius-bottom-left: $default-border-radius;
$default-module-border-radius-bottom-right: $default-border-radius;
$default-module-border-radius: $default-border-radius;

$default-grid-min-width: 400px;

$default-body-padding-bottom: 100px;


:root {
    /* ---------- 1.1: Typography ---------- */
    
    --defaultFontFamilyHeadline: $default-font-family-headline;
    --defaultFontFamilyText: $default-font-family-text;
    /* ----- 1.1.2: Font-size ----- */

    --titleFontSize: 1.5em;
    --legendHeadFontSize: 15px;
    --regularFontSize: 12px;
    --inputFontSize: 13px;
    --mobileInputFontSize: 13px;
    --searchBtnFontSize: 14px;
    --bigFontSize: 27px;
    --reallyBigFontSize: 150px;
    /* ----- 1.1.3: Font-weight ----- */

    --fontWeight1: 300;
    --fontWeight2: 500;
    --fontWeight3: 700;
    --fontWeight4: 700;
    /* ----- 1.1.4: Line-height ----- */

    --lineHeight1: 15px;
    /* ---------- 1.2: Colors ---------- */
    /* ----- 1.2.1: Text-color ----- */

    --textColorDark: #333333;
    --textColorGrey: #707070;
    --textColorLightGrey: #AAA;
    --textColorLight: #FFFFFF;
    --textColorRed: #CC3B33;
    /* ----- 1.2.2: Background-color ----- */

    --backgroundColor1: #FFFFFF;
    --backgroundColor2: #F5F5F5;
    --backgroundColor3: #DDDDDD;
    --backgroundColorNotAllowed: #E3E3E3;
    --modalBackgroundColor1: rgba(0, 0, 0, 0.5);
    /* ----- 1.2.3: Button-color ----- */

    --buttonColorGreen: #15C39A;
    --buttonColorGreenRGB: 21, 195, 154;
    --buttonColorGrey: #AAAAAA;
    --buttonColorGreyRGB: 170, 170, 170;
    --buttonColorRed: #CC3B33;
    --buttonColorRedRGB: 204, 59, 51;
    --buttonColorBlue: #226285;
    --buttonColorBlueRGB: 34, 98, 133;
    /* ----- 1.2.4: Style-color ----- */

    --styleColor1: #15C39A;
    --styleColor2: #264653;
    --styleColor3: #FFBE0B;
    --styleColor4: #333333;
    --styleColor5: #f5f5f5;
    --focusColor1: #333333;
    /* ---------- 1.3: Shadow ---------- */

    --shadow1: 0px 0px 60px -30px rgba(51, 51, 51, 0.5);
    --shadow2: 0px 10px 60px 0px rgba(0, 0, 0, 0.2);
    --shadow3: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0 1px 3px -1px;
    /* ---------- 1.4: Spacing ---------- */
    /* ----- 1.4.1: Margin-spacing ----- */

    --margin1: 15px;
    --margin2: 0 0 0 10px;
    --margin3: 0 0 15px 0;
    --margin4: 0 0 10px 0;
    --margin5: 20px 0 0 0;
    --margin6: 0 0 0 15px;
    --margin7: 0 10px;
    --marginSides1: 0 15px;
    --iconMargin: 10px;
    --marginTop1: 50px;
    --marginTop2: 25px;
    --marginTop3: 15px;
    --marginTop4: 10px;
    --marginTop5: 5px;
    --marginBottom1: 50px;
    --marginBottom2: 25px;
    --marginBottom3: 15px;
    --marginBottom4: 10px;
    --marginBottom5: 5px;
    --marginLeft1: 50px;
    --marginLeft2: 25px;
    --marginLeft3: 15px;
    --marginLeft4: 10px;
    /* ----- 1.4.2: Column-spacing ----- */

    --columnGap1: 50px;
    --columnGap2: 25px;
    --columnGap3: 15px;
    --columnGap4: 10px;
    --columnGap5: 5px;
    --columnGap6: 7px;
    /* ----- 1.4.3: row-spacing ----- */

    --rowGap1: 20px;
    /* ----- 1.4.4: Padding-spacing ----- */

    --padding1: 5px 15px;
    --padding2: 15px;
    --padding3: 0 0 15px 0;
    --padding4: 5px 15px 5px 15px;
    --padding5: 0 15px;
    --padding6: 5px 15px 5px 15px;
    --inputPadding: 5px 35px 5px 15px;
    /* ----- 1.4.5: Height-spacing ----- */

    --headerHeight1: 50px;
    --headerHeightMobile1: 36px;
    --inputHeight1: 30px;
    /* ----- 1.4.6: Width-spacing ----- */

    --widthWithMargin1: calc(50% - 25px);
    --columnWidth: #{$default-grid-min-width};
    /* ---------- 1.5: borders ---------- */
    /* ----- 1.5.1: Border ----- */

    --borderBottom1: 1px solid rgba(102, 102, 102, 0.25);
    --borderRight1: 2px solid #333;
    --borderRight2: 2px solid #F5F5F5;
    /* ----- 1.5.2: Border-radius ----- */

    --roundCorner1: 10px;
    --roundCorner2: 50px;
    --roundCornerTopLeft1: 10px;
    --roundCornerTopRight1: 10px;
    --roundCornerBottomLeft1: 10px;
    --roundCornerBottomRight1: 10px;
    /* ---------- 1.6: Icons ---------- */

    --caretSize1: 17px;
    --caretSizeContainer1: 25px;
    --caretInputBackground1: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22caret-down%22%20class%3D%22svg-inline--fa%20fa-caret-down%20fa-w-10%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M31.3%20192h257.3c17.8%200%2026.7%2021.5%2014.1%2034.1L174.1%20354.8c-7.8%207.8-20.5%207.8-28.3%200L17.2%20226.1C4.6%20213.5%2013.5%20192%2031.3%20192z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
    --caretInputBackgroundPos1: right 1.1em top 54%, 0 0;
    --caretInputBackgroundSize1: .65em auto, 100%;
    --caretInputBackgroundRepeat1: no-repeat, repeat;
    /* ---------- 1.7: Animations ---------- */

    --transition1: 0.4s ease-in-out;

    --safe-area-inset-top: env(safe-area-inset-top, 20px);
    --safe-area-inset-bottom: env(safe-area-inset-bottom, 20px);
    --safe-area-inset-left: env(safe-area-inset-left, 20px);
    --safe-area-inset-right: env(safe-area-inset-right, 20px);
}