﻿$login-gap: 30px;
$login-form-padding: 15px;

/* --------------- Content Section Login --------------- */
.login-body {
    background-color: $default-style-color-4;
    height: 100vh;

    &#root {
        flex-direction: unset;

        .sidebar:not(.show-background) {
            background: unset;
        }

        //@include size(tablet) {
        //    padding: 25px;
        //}
    }

    .background {
        width: 100vw;
        height: 100vh;
        position: fixed;
        user-select: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


    .login-form {
        position: relative;
        /*height: 100%;*/
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        row-gap: $login-gap;
        align-content: center;
        /*height: calc(100% - 60px);*/

        form {
            width: 100%;
            /*height: 100%;*/
            display: flex;
            flex-flow: row wrap;
        }
    }

    .content-container-login {
        padding: 50px;
        background-color: #FFFFFF;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        height: 100vh;
        width: 500px;
        overflow-y: scroll;
        top: 0;
        right: 0;
        //box-shadow: 20px 40px 50px 0px rgba(0,0,0,1);
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: $login-gap;
        overflow-x: hidden;

        & > *:first-child {
            margin-top: auto;

            @include size(mobile) {
                margin-top: unset;
                margin-bottom: auto;
            }
        }

        & > *:last-child {
            margin-bottom: auto;

            @include size(mobile) {
                margin-bottom: unset;
                margin-top: auto;
            }
        }

        @include size(tablet) {
            width: clamp(420px, 80vw, 500px);
            height: fit-content;
            max-height: 95vh;
            box-shadow: 10px 20px 40px 0px rgb(0 0 0 / 30%);
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            overflow-y: visible;
            padding: 25px;
            border-radius: 10px;
            
            //border: 1px dotted red;
            //padding: 0 clamp(50px, 25vw, 500px);
        }

        @include size(mobile) {
            width: 100%;
            box-shadow: none;
            height: 100vh;
            max-height: 100vh;
            align-content: baseline;
            position: unset;
            top: unset;
            right: unset;
            transform: unset;
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    .login-title {
        font-size: var(--bigFontSize);
        font-weight: 900;
        padding: 0 0 0 $login-form-padding;
        margin: 0;
        width: 100%;
    }

    .login-content-group-container-top {
        width: 100%;

        .login-title {
            margin-bottom: 15px;
        }

        & > p {
            width: 100%;
            padding: 0 0 0 $login-form-padding;
        }

        & > .text-danger {
            margin: 0;
        }
    }

    .login-content-group-container-bottom {
        position: relative;
        bottom: 0;
        width: 100%;
        margin-top: auto;
    }

    .login-content-group {
        margin-bottom: var(--marginBottom3);

        &.error-box {
            background: var(--styleColor3);
            width: 100%;
            border-radius: 10px;
            padding: 15px;

            p, a {
                padding: 0;
                margin: 0;
            }

            .login-error:not(:first-child), a {
                margin: 10px 0 0 0;
                display: block;
            }
        }

        & > p {
            font-size: $default-font-size;
            font-weight: var(--fontWeight2);
            width: 100%;
            margin-bottom: 2px;
            color: $default-font-color-dark;
            padding: var(--padding5);
        }

        & .login-error {
            /*font-size: 15px;*/
            font-weight: 700;
        }

        & a {
            font-size: $default-font-size;
            font-weight: var(--fontWeight3);
            width: 100%;
            margin-bottom: 2px;
            color: $default-font-color-dark;
            padding: var(--padding5);
        }
    }

    .login-logo {
        width: 100%;
        height: 50px;
        margin: 0;
        padding-left: $login-form-padding;
        user-select: none;

        a {
            padding: 0;
            margin: 0;
            color: unset;
            background: none;
        }

        img {
            /*max-width: 400px;
            min-width: 150px;
            width: 20vw;*/
            height: 100%;
        }
    }

    .text-danger {
        width: 100%;
        margin: 5px 0 0 15px;
    }

    .min-req-pwd {
        width: 100%;
    }

    .min-req-pwd-title, .min-req-pwd-arg {
        font-size: $default-font-size;
        font-weight: var(--fontWeight2);
        width: 100%;
        margin-bottom: 2px;
        color: red;
        padding: 0;
    }
    /* Password indicator */

    #pwd-container {
        margin-bottom: var(--marginBottom3);
        border-radius: 10px;
        overflow: hidden;

        .progress {
            background: $default-font-color-light !important;
            height: 8px;

            .progress-bar {
                /*background: $default-style-color-1 !important;*/

                &.bg-danger {
                    background: #AF2833 !important;
                }

                &.bg-warning {
                    background: #D1821D !important;
                }

                &.bg-success {
                    background: $default-style-color-1 !important;
                }
            }
        }
    }

    .sidebar {
        flex-direction: column-reverse;
        
        @include size(tablet) {
            width: 100%;
            position: relative;
            height: fit-content;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            gap: 40px;
            padding: 0;
            
            &.anonymous {
                position: absolute;
                bottom: 0;
                padding: 15px;
            }
        }
    }

    .sponsors {
        width: 100%;

        * {
            width: 100%;
        }

        .sponsor {
            display: flex;
            justify-content: center;
            flex-flow: row wrap;
            text-align: center;

            img {
                width: clamp(150px, 70%, 100%);

                @include size(tablet) {
                    width: clamp(150px, 50%, 100%);
                }
            }
        }
    }
}
