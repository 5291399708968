﻿.navigation {
    background: $default-style-color-5;
    width: 250px;
    max-width: 250px;
    min-width: 250px;
    max-height: 100vh;
    height: 100vh;
    padding: $default-menu-padding;
    flex: 0 1 auto;
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
    transition: width 0.1s ease-in-out;

    * {
        user-select: none;
        color: $default-font-color-dark;
    }
    
    &.closed {
        //width: fit-content;
        width: 50px;
        min-width: unset;
        max-width: unset;
    }

    .selectors {
        width: 100%;
    }
}

.menu {
    //width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    padding-bottom: 20px;

    @include size(tablet) {
        overflow-y: unset;
    }
    
    * {
        color: var(--styleColor4);
    }
    
    & > .item, & > .permission-wrapper {
        position: relative;
        left: -10px;
        width: calc(100% + 20px);
    }

    .item {
        
        @include size(tablet) {
            margin-bottom: 10px;
        }
        
        &.group {
            //&:not(:last-child) {
            //    margin-bottom: 10px;
            //}

            & > p, & > a {
                font-weight: 700;
                font-size: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px;

                @include size(tablet) {
                    font-size: 1.3em;
                }

                i {
                    position: relative;
                    top: 2px;
                }
            }
        }

        &:not(.group) {
            padding-left: 10px;

            @include size(tablet) {
                padding-left: 20px;
            }

            p, a {
                padding: 0 20px;
                display: block;
                white-space: nowrap;

                @include size(tablet) {
                    padding: 0 30px;
                }

                &:before {
                    content: "\f111";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 700;
                    font-size: 5px;
                    position: absolute;
                    left: 10px;
                    padding-top: 1px;
                    
                    @include size(tablet) {
                        font-size: 6px;
                    }
                }
            }
            
            p {
                @include size(tablet) {
                    @include hoverStyle(link) {
                        font-weight: unset !important;
                    }
                }
            }

            &.has-children {
                & > p:before, & > a:before {
                    content: "\f107";
                    font-size: unset;
                    padding-top: 0;
                    
                    @include size(tablet) {
                        font-size: 1.3em;
                    }
                }

                &.closed {
                    & > p:before, & > a:before {
                        content: "\f105";
                        font-size: unset;
                        padding-top: 0;
                    }
                }
            }
        }

        p, a {
            margin: 0;
            line-height: $default-menu-lineHeight;
            cursor: pointer;
            position: relative;
            padding: 0 10px;

            @include size(tablet) {
                margin-bottom: 10px;

                @include hoverStyle(link) {
                    &:not(.active) {
                        background: unset !important;
                        border-radius: unset !important;
                    }
                    
                    a {
                        font-weight: unset !important;
                    }
                }
            }

            @include hoverStyle(link) {
                background: #CCC;
                border-radius: 3px;
                position: relative;
            }

            &.active {
                background: rgba(0, 0, 0, 0.1);
                border-radius: 3px;
                position: relative;

                //.active {
                //    background: green;
                //}
            }
        }


        a {
            @include size(tablet) {
                @include hoverStyle(link) {
                    font-weight: unset !important;
                }
            }
        }

        &.inactive {
            p, a {
                color: rgba(0, 0, 0, 0.4);
            }
        }
        
        &.closed {
            & .item {
                display: none !important;
            }
        }
    }

    //.bottom-link {
    //    text-align: center;
    //}
}