﻿/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.cookieBanner .banner {
    //width: 350px;
    display: flex;
    gap: 10px;
    flex-flow: column wrap;

    @include size(tablet) {
        width: 100%;
        gap: 15px;
    }

    & > * {
        margin: 0;
    }
}

.nav-footer-login .cookieBanner .banner {
    right: 560px;
}

.nav-footer-login .cookieBanner .closedCookieBanner {
    right: 60px;
}

.cookieBanner h4 {
    font-family: $default-font-family-headline;
    font-size: var(--titleFontSize);
    font-weight: var(--fontWeight4);
    margin: 0 0 15px 0;
    /*display: none;*/
}

.cookieBanner p {
    font-family: $default-font-family-text;
    /*font-weight: var(--fontWeight2);*/
    font-size: $default-font-size;
    margin: 0 0 15px 0;
    /*display: none;*/
}

    .cookieBanner p .cookieBtnStatus {
        font-weight: var(--fontWeight3);
    }

.cookieBanner .cookieBtns {
    width: 100%;
    /*display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;*/
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    /*display: none;*/
}

.cookieBanner .cookieDescGroup .cookieBtns {
    width: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.cookieBanner #readMorePopup, .cookieBanner #settingsPopup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
}

.cookieBanner .popupContent {
    width: 70vw;
    max-width: 1000px;
    max-height: 80vh;
    overflow-y: auto;
    z-index: 9999;
    /*background: var(--backgroundColor1);*/
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /*padding: 20px 20px 40px 20px;
    border-radius: 10px;
    box-shadow: var(--shadow2);*/
}

    .cookieBanner .popupContent h4 {
        text-align: center;
    }

.showCookieBanner {
    color: $default-font-color-dark;
    text-align: center;
    width: 100%;
    border: none;
    background: none;
    display: none;
}

    .showCookieBanner:hover {
        color: var(--styleColor1);
    }

.cookieDescGroup {
    margin: 0 0 50px 0;

    &:only-child {
        margin: 0;
    }
}

    .cookieDescGroup h5 {
        font-weight: var(--fontWeight3);
    }

    .cookieDescGroup h6 {
        font-size: 14px;
        font-weight: var(--fontWeight3);
        color: $default-font-color-dark;
    }

    .cookieDescGroup ul li {
        margin: 0 0 10px 0;
    }

.cookieOnlyMobile .banner {
    display: none;
}

.cookieName {
    font-weight: var(--fontWeight3);
}

.cookiesShow {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

/*.cookiesHide {
    display: none !important;
}*/

.cookiesHide {
    display: block !important;
}

.cookieBanner .closedCookieBanner {
    background: var(--backgroundColor1);
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 40px;
    position: absolute;
    right: 0;
}

.cookieBanner.cookieOnlyMobile .closedCookieBanner {
    display: none;
}

/*@media only screen and (max-width: 1224px) {
    .cookieBanner .banner {
        right: 90px;
    }

    .cookieBanner .closedCookieBanner {
        right: 0;
    }

    .nav-footer-login > p {
        margin-bottom: 40px;
    }

    .nav-footer-login .cookieBanner .closedCookieBanner, .nav-footer-login .cookieBanner .banner {
        right: unset;
        left: 60px;
    }
}

@media only screen and (max-width: 768px) {
    .cookieOnlyMobile .banner {
        display: block;
        width: 100%;
        right: 0;
    }

    .cookieOnlyMobile .closedCookieBanner {
        display: none;
    }

    .cookieOnlyMobile .banner h4 {
        text-align: center;
    }

    .cookieOnlyMobile .banner p {
        text-align: center;
    }

    .cookieBanner.cookieOnlyMobile .popupContent {
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        border-radius: 0;*/
        /*padding: 50px 7vw 95px 7vw;*/
        /*overflow: hidden;
    }

        .cookieBanner.cookieOnlyMobile .popupContent .tab-content {
            overflow-y: scroll;
            padding: 15px 7vw;
        }
}*/
